import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { GameModel, GamesOnUsersWith } from "@pastukh-dm/wboard-games";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CreateEntityDialog } from "src/components/dialogs/EntityDialog/CreateEntityDialog";
import { FieldType } from "src/components/dialogs/EntityDialog/EntityDialogForm";
import { useFetch } from "src/hooks/useFetch";
import * as z from "zod";

export const AddUserGameButton = ({
  userGames,
}: {
  userGames: GamesOnUsersWith<"game">[];
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { data: allGames } = useFetch<GameModel[]>({
    url: `/games`,
  });

  const options = useMemo(
    () =>
      (allGames || [])
        .filter(
          (game) =>
            !userGames.some((userGame) => userGame.game.geekId === game.geekId)
        )
        .map((game) => ({
          value: game.geekId.toString(),
          label: `${game.name} (${game.geekId})`,
        })),
    [allGames, userGames]
  );

  return (
    <>
      <CreateEntityDialog
        createUrl={`/users/${id}/games`}
        entityName={"User game"}
        onSuccess={() => {
          navigate(0);
        }}
        // validationSchema={validationSchema}
        open={open}
        onOpenChange={setOpen}
        fields={{
          geekIds: {
            type: FieldType.AUTOCOMPLETE_MULTIPLE,
            options,
            validation: z.string().array(),
          },
        }}
      />
      <Button
        startIcon={<Add />}
        onClick={() => setOpen(true)}
        variant="outlined"
        color="primary"
      >
        User game
      </Button>
    </>
  );
};
