import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { MeetupModel, UserModel } from "@pastukh-dm/wboard-games";
import { useState } from "react";
import { FieldType } from "src/components/dialogs/EntityDialog/EntityDialogForm";
import { FormDialog } from "src/components/dialogs/FormDialog";
import { useFetch } from "src/hooks/useFetch";
import { MaterialColor } from "src/types/MaterialColor";

export const AddMeetupApplicationButton = ({
  meetup,
  refetch,allUsers
}: {
  meetup: MeetupModel;
  refetch: () => void;
  allUsers: UserModel[]
}) => {
  const [open, setOpen] = useState(false);

  //   const { data: meetups } = useFetch<{ id: string; name: string }[]>({
  //     url: "/meetups/",
  //   });
//   const { data: users } = useFetch<UserModel[]>({ url: "/users" });

  return (
    <>
      <Button
        startIcon={<Add />}
        onClick={() => setOpen(true)}
        variant="outlined"
        color="primary"
        size="small"
      >
        Add Application
      </Button>
      <FormDialog
        open={open}
        title={"Create application"}
        fields={{
          meetupId: {
            type: FieldType.TEXT,
            defaultValue: meetup.id,
            disabled: true,
          },
          userId: {
            type: FieldType.AUTOCOMPLETE,
            options:
            allUsers?.map((user) => ({
                label: `${user.name || "-"} (${user.firstName || "-"} ${
                  user.lastName || "-"
                }) - ${user.email}`,
                value: user.id,
              })) || [],
          },
        }}
        submitUrl={"/applications"}
        submitText={"Create"}
        onSuccess={refetch}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
