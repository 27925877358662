import * as yup from "yup";
import {
  columnCopy,
  columnFormatApplicant,
  columnFormatBoolean,
  DeprecatedEntityTableProps,
  deprecatedRenderColumn,
} from "../../components/EntityTable/DeprecatedEntityTable";
import { Feedback } from "./Feedback";

export const useFeedbacksTable = (): DeprecatedEntityTableProps<Feedback> => {
  const sharedProps: DeprecatedEntityTableProps<Feedback> = {
    entityName: "Feedback",
    validationSchema: yup.object().shape({}) as any,
    apiUrls: {
      create: "/feedbacks",
      read: "/feedbacks",
      update: "/feedbacks/:id",
      delete: "/feedbacks/:id",
    },
    columns: [
      deprecatedRenderColumn("id", columnCopy({ maxLength: 12 })),
      // //   "gameId",
      // "gameId",
      // {
      //   name: "geekInfo",
      //   options: {
      //     customBodyRender: (item) => item?.name,
      //   },
      // },
      // "applicationId",
      deprecatedRenderColumn("applicant", columnFormatApplicant),
      "rating",
      "comments",
      "depositAction",
      deprecatedRenderColumn("subscribe", columnFormatBoolean),
      // renderColumn("user", columnFormatApplicant),
      // "interest",
      // "experience",
      // renderColumn("createdAt", columnFormatDate),
      // renderColumn("updatedAt", columnFormatDate),
    ],
    datatableOptions: {
      sortOrder: { name: "updatedAt", direction: "desc" },
    },
    // itemUrl: "/meetups/:id",
  };
  return { ...sharedProps };
};
