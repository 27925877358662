import { Tabs, Tab, Box, Badge } from "@mui/material";
import { ReactNode, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type Props = {
  searchPrefix?: string;
  orientation?: "horizontal" | "vertical";
  color?: "primary" | "secondary";
  tabs: {
    // label: string;
    key: string;
    content: ReactNode;
    count?: number;
  }[];
};

export const AppTabs = ({ tabs, searchPrefix, orientation, color }: Props) => {
  const [search, setSearch] = useSearchParams();
  const searchKey = searchPrefix ? `${searchPrefix}_tab` : "tab";
  const tabKey = search.get(searchKey);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const index = tabs.findIndex((tab) => tab.key === tabKey);
    if (index > -1) {
      setSelectedTab(index);
    }
  }, [tabKey]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSearch(
      (prev) => {
        const params = new URLSearchParams(prev);
        params.set(searchKey, tabs[newValue].key);
        return params;
      },
      { replace: true }
    );
  };
  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        orientation={orientation}
        textColor={color}
        indicatorColor={color}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            label={
              <>
                {tab.count ? (
                  <Badge
                    // color="primary"
                    badgeContent={tab.count}
                    sx={{ pr: "13px" }}
                    max={999}
                  >
                    {tab.key}
                  </Badge>
                ) : (
                  tab.key
                )}
              </>
            }
            key={tab.key}
          />
        ))}
      </Tabs>
      <Box py={4}>
        <>{tabs[selectedTab].content}</>
      </Box>
    </>
  );
};
