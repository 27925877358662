import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import axios, { Method } from "axios";
import { ReactNode, useState } from "react";
import { useNotification } from "../../hooks/useNotification";
import { AppBaseDialog } from "../dialogs/AppBaseDialog";
import { DeprecatedEntityItem } from "./DeprecatedActionEntity";

/** @deprecated */
export type DeprecatedActionEntityManyProps = {
  url: string;
  entityName: string;
  items: DeprecatedEntityItem[];
  onSuccess: () => void;
  icon: ReactNode;
  actionName: string;
  method?: Method;
};

/** @deprecated */
export const DeprecatedActionEntityMany = ({
  url,
  entityName,
  actionName,
  items,
  onSuccess,
  method,
  icon,
}: DeprecatedActionEntityManyProps) => {
  const [open, setOpen] = useState(false);

  function handleButtonClick() {
    setOpen(true);
  }

  const { showNotification, showErrorNotification } = useNotification();
  const onSubmit = async () => {
    try {
      const res = await axios<{ message: string }>({
        method: method || "POST",
        url,
        data: {
          ids: items.map((i) => i.id),
        },
      });
      showNotification({
        severity: "success",
        message: res.data.message,
      });
      onSuccess();
      setOpen(false);
    } catch (e: any) {
      showErrorNotification(e);
    }
  };

  return (
    <>
      <Tooltip title={actionName}>
        <IconButton onClick={handleButtonClick}>{icon}</IconButton>
      </Tooltip>
      <AppBaseDialog
        open={open}
        title={`${actionName} ${entityName}?`}
        maxWidth="sm"
        content={
          <>
            {/* <pre>{JSON.stringify(items, null, "\t")}</pre> */}
            {/* <JsonTable json={item} /> */}
            <Typography>Items: {items.length}</Typography>
            <Box mt={4}>
              <Button variant="contained" onClick={onSubmit}>
                {actionName}
              </Button>
            </Box>
          </>
        }
      />
    </>
  );
};
