import { Alert, Button, IconButton, Tooltip, Typography } from "@mui/material";
import axios, { Method } from "axios";
import { ReactNode, useMemo, useState } from "react";
import { AnyObjectSchema } from "yup";
import { useNotification } from "../../hooks/useNotification";
import { AppBaseDialog } from "./AppBaseDialog";
import { DeprecatedEntityForm } from "../EntityTable/DeprecatedEntityForm";

type Props = {
  url: string;
  icon: ReactNode;
  text: string;
  onSuccess?: () => void;
  validationSchema?: AnyObjectSchema | any;
  defaultValues?: any;
  method?: Method;
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
};

export const DialogAction = ({
  url,
  onSuccess,
  icon,
  text,
  validationSchema,
  defaultValues,
  method,
  color,
}: Props) => {
  const [open, setOpen] = useState(false);

  function handleButtonClick() {
    setOpen(true);
  }

  const { showNotification } = useNotification();
  const [error, setError] = useState<string | false>(false);
  const onSubmit = async (data?: any) => {
    try {
      setError(false);
      const res = await axios<{ message: string }>({
        url,
        method: method || "post",
        data,
      });
      showNotification({
        severity: "success",
        message: res.data.message,
      });
      onSuccess?.();
      setOpen(false);
    } catch (e: any) {
      const message =
        e?.response?.data?.error || e?.response?.data?.message || "Error";
      setError(message);
      showNotification({
        severity: "error",
        message,
      });
    }
  };

  return (
    <>
      <Tooltip title={text}>
        <IconButton onClick={handleButtonClick}>{icon}</IconButton>
      </Tooltip>
      <AppBaseDialog
        open={open}
        title={text}
        maxWidth="sm"
        content={
          <>
            {validationSchema ? (
              <DeprecatedEntityForm
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                defaultValues={defaultValues}
              />
            ) : (
              <>
                <Typography mb={4}>{text}?</Typography>
                <Button
                  variant="contained"
                  onClick={() => onSubmit()}
                  color={color}
                >
                  {text}
                </Button>
                {error && (
                  <Alert severity="error" sx={{ mt: 4 }}>
                    {error}
                  </Alert>
                )}
              </>
            )}
          </>
        }
      />
    </>
  );
};
