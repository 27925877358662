import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link, useLocation } from "react-router-dom";

export const NoMatchPage = () => {
  let location = useLocation();
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h1">404</Typography>
      <Typography variant="h2">Page not found</Typography>
      <Typography variant="h4" sx={{ mb: 5 }}>
        {location.pathname}
      </Typography>
      <Typography variant="body1">
        <Link to="/">Go to the home page</Link>
      </Typography>
    </Box>
  );
};
