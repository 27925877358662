import { Box } from "@mui/material";
import MUIDataTable, {
  MUIDataTableMeta,
  MUIDataTableOptions,
  MUIDataTableProps,
} from "mui-datatables";
import { ReactNode, useMemo } from "react";

type Props = {
  columns: MUIDataTableProps["columns"];
  data: MUIDataTableProps["data"];
  title: string | ReactNode;
  rowActions?: (
    index: MUIDataTableMeta["rowIndex"],
    data: MUIDataTableMeta["rowData"]
  ) => ReactNode;
  datatableOptions?: MUIDataTableOptions;
};
export const AppTable = ({
  title,
  columns,
  data,
  rowActions,
  datatableOptions,
}: Props) => {
  const actualColumns = useMemo(() => {
    return rowActions
      ? ([
          ...columns,
          {
            name: "Actions",
            options: {
              sort: false,
              customBodyRender: (_, meta) => {
                const index = (meta.currentTableData[meta.rowIndex] as any)
                  .index;

                // return rowActions(meta.rowIndex, meta.rowData);
                return rowActions(index, meta.rowData);
              },
            },
          },
        ] as MUIDataTableProps["columns"])
      : columns;
  }, [columns, rowActions]);

  return (
    <Box sx={{ width: "100%" }}>
      <MUIDataTable
        title={title}
        data={data}
        columns={actualColumns}
        options={{
          filterType: "checkbox",
          resizableColumns: false,
          print: false,
          filter: false,
          viewColumns: false,
          download: false,
          selectableRows: "multiple",
          customSearch: (query, currentRow) => {
            return (
              JSON.stringify(currentRow)
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
          },
          ...datatableOptions,
        }}
      />
    </Box>
  );
};
