import {
  Box,
  Button,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import {
  MUIDataTableColumnDef,
  MUIDataTableColumnOptions,
  MUIDataTableOptions,
  TableToolbar,
} from "mui-datatables";
import { useFetch } from "../../hooks/useFetch";
import { AppTable } from "../AppTable";
import { PageTitle } from "../PageTitle";
import { DeprecatedCreateEntity } from "./DeprecatedCreateEntity";
import { DeprecatedDeleteEntity } from "./DeprecatedDeleteEntity";
import * as yup from "yup";
import ArticleIcon from "@mui/icons-material/Article";
import { Link } from "react-router-dom";
import axios from "axios";
import { DeprecatedUpdateEntity } from "./DeprecatedUpdateEntity";
import { formatDatetime } from "../../helpers/formatHelper";
import {
  DeprecatedActionEntity,
  DeprecatedActionEntityProps,
  DeprecatedEntityItem,
} from "./DeprecatedActionEntity";
import {
  DeprecatedActionEntityMany,
  DeprecatedActionEntityManyProps,
} from "./DeprecatedActionEntityMany";
import { CopyProps, CopyToClipboard } from "../CopyToClipboard";
import { useMemo } from "react";

/** @deprecated */
export type DeprecatedEntityTableProps<T = any> = {
  entityName: string;
  itemUrl?: string;
  apiUrls: {
    create?: string;
    read?: string;
    update?: string;
    delete?: string;
  };
  onRowClick?: (e: T) => void;
  data?: T[];
  columns: MUIDataTableColumnDef[];
  validationSchema: yup.ObjectSchema<any>;
  itemActions?: Omit<
    DeprecatedActionEntityProps,
    "item" | "entityName" | "onSuccess"
  >[];
  multipleActions?: Omit<
    DeprecatedActionEntityManyProps,
    "items" | "entityName" | "onSuccess"
  >[];
  datatableOptions?: MUIDataTableOptions;
  singleItem?: boolean;
};

/** @deprecated */
export const DeprecatedEntityTable = ({
  entityName,
  apiUrls,
  columns,
  itemUrl,
  validationSchema,
  itemActions,
  multipleActions,
  datatableOptions,
  onRowClick,
  singleItem,
  data,
}: DeprecatedEntityTableProps) => {
  const {
    data: items,
    isLoading,
    error,
    refetch,
  } = useFetch<DeprecatedEntityItem[]>({
    url: !data ? apiUrls.read : undefined,
  });
  const currentItems = useMemo(() => data || items, [items, data]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  if (currentItems === null) {
    return <p>NULL</p>;
  }
  return (
    <Box>
      {!!currentItems ? (
        <AppTable
          title={
            <Stack direction="row" sx={{ alignItems: "center" }} gap={2}>
              <Typography variant="h6">{entityName}</Typography>
              {!singleItem && !!apiUrls.create && (
                <DeprecatedCreateEntity
                  createUrl={apiUrls.create}
                  entityName={entityName}
                  onSuccess={refetch}
                  validationSchema={validationSchema}
                />
              )}
            </Stack>
          }
          columns={columns}
          data={currentItems}
          rowActions={(index, data) => (
            <Stack direction="row" justifyContent="flex-end">
              {itemActions?.map((action) => (
                <DeprecatedActionEntity
                  {...action}
                  item={currentItems[index]}
                  entityName={entityName}
                  key={action.actionName}
                  onSuccess={refetch}
                />
              ))}

              {itemUrl && (
                <Link to={itemUrl.replace(":id", currentItems[index].id)}>
                  <IconButton color="primary">
                    <ArticleIcon />
                  </IconButton>
                </Link>
              )}
              {!!apiUrls.update && (
                <DeprecatedUpdateEntity
                  updateUrl={apiUrls.update}
                  entityName={entityName}
                  onSuccess={refetch}
                  validationSchema={validationSchema}
                  item={currentItems[index]}
                />
              )}
              {!!apiUrls.delete && (
                <DeprecatedDeleteEntity
                  deleteUrl={apiUrls.delete}
                  entityName={entityName}
                  item={currentItems[index]}
                  onSuccess={refetch}
                />
              )}
            </Stack>
          )}
          datatableOptions={{
            pagination: !singleItem,
            rowsPerPage: singleItem ? 1 : 10,
            rowsPerPageOptions: [10, 20, 50, 100],
            customToolbarSelect: (
              selectedRows,
              displayData,
              setSelectedRows
            ) => {
              const selectedData = selectedRows.data.map((row) => {
                const dataIndex = row.dataIndex;
                return currentItems[dataIndex];
              });
              return (
                <Stack direction="row" alignItems="flex-end">
                  {multipleActions?.map((action) => (
                    <DeprecatedActionEntityMany
                      {...action}
                      items={selectedRows.data.map(
                        (row) => currentItems[row.dataIndex]
                      )}
                      entityName={entityName}
                      key={action.actionName}
                      onSuccess={refetch}
                    />
                  ))}
                </Stack>
              );
            },
            selectableRows: singleItem ? "none" : undefined,
            search: singleItem ? false : undefined,
            onRowClick: onRowClick
              ? (e, meta) => {
                  const item = currentItems[meta.dataIndex];
                  onRowClick(item);
                }
              : undefined,
            ...datatableOptions,
          }}
        />
      ) : (
        "No data"
      )}
    </Box>
  );
};

/** @deprecated */
export const DeprecatedDataEntityTable = <T,>(
  props: DeprecatedEntityTableProps<T> & {
    data: T[];
    apiUrls: Omit<DeprecatedEntityTableProps["apiUrls"], "read">;
    singleItem?: boolean;
  }
) => <DeprecatedEntityTable {...props} />;

/** @deprecated */
export const DeprecatedFetchEntityTable = <T,>(
  props: Omit<DeprecatedEntityTableProps<T>, "data"> & {
    apiUrls: DeprecatedEntityTableProps["apiUrls"] & { read: string };
  }
) => <DeprecatedEntityTable {...props} />;

/** @deprecated */
export const deprecatedRenderColumn = (
  name: string,
  render: (e: any) => any,
  options?: MUIDataTableColumnOptions
) => ({
  name,
  options: {
    customBodyRender: render,
    ...options,
  },
});
/** @deprecated */
export const columnFormatLink = (link: string) => (e: any) =>
  <Link to={link}>e</Link>;
/** @deprecated */
export const columnFormatDate = (e: any) => (
  <Box whiteSpace="nowrap">{formatDatetime(e)}</Box>
);
/** @deprecated */
export const columnFormatApplicant = (e: any) => (
  <Box>
    <b>{e?.name}</b> ({e?.email})
  </Box>
);
/** @deprecated */
export const columnFormatMeetup = (e: any) => (
  <Box>
    <b>{e?.name || "-"}</b>
    {/* <b>{JSON.stringify(e)}</b> */}
  </Box>
);
/** @deprecated */
export const columnFormatBoolean = (e: any) => (
  <Box>
    <b>
      <Switch checked={e} disabled />
    </b>
  </Box>
);
/** @deprecated */
export const columnCopy = (props: Omit<CopyProps, "content">) => (e: any) =>
  (
    <Box>
      <CopyToClipboard content={e} {...props} />
    </Box>
  );
