import { MeetupLevel, MeetupModel } from "@pastukh-dm/wboard-games/lib";
import {
  deprecatedCreateEntityFieldMeta,
  DeprecatedEntityFieldType,
} from "src/components/EntityTable/DeprecatedEntityForm";
import {
  DeprecatedEntityTable,
  DeprecatedDataEntityTable,
  DeprecatedEntityTableProps,
  deprecatedRenderColumn,
  columnCopy,
  columnFormatDate,
} from "src/components/EntityTable/DeprecatedEntityTable";
import * as yup from "yup";

export const OldMeetupsPage = () => {
  return <MeetupsTable />;
};

export const MeetupsTable = () => {
  const props = useMeetupTable();
  return <DeprecatedEntityTable {...props} />;
};

export const MeetupsDataTable = ({
  data,
  singleItem,
}: {
  data: MeetupModel[];
  singleItem?: boolean;
}) => {
  const props = useMeetupTable();
  return (
    <DeprecatedDataEntityTable {...props} data={data} singleItem={singleItem} />
  );
};

const useMeetupTable = (): DeprecatedEntityTableProps<MeetupModel> => {
  // const navigate = useNavigate();
  const sharedProps: DeprecatedEntityTableProps<MeetupModel> = {
    entityName: "Meetup",
    validationSchema: yup.object({
      name: yup.string().required(),
      address: yup.string().required(),
      level: yup
        .mixed()
        .oneOf(Object.values(MeetupLevel))
        .required()
        .meta(
          deprecatedCreateEntityFieldMeta(
            DeprecatedEntityFieldType.AUTOCOMPLETE,
            {
              options: Object.values(MeetupLevel).map((value) => ({ value })),
            }
          )
        ),
      startAt: yup.date().required(),
      notified: yup
        .boolean()
        .required()
        .meta(
          deprecatedCreateEntityFieldMeta(DeprecatedEntityFieldType.CHECKBOX)
        ),
      applyFrom: yup.date().required(),
      applyTo: yup.date().required(),
      chatLink: yup.string().optional().nullable(),
    }) as any,
    apiUrls: {
      create: "/meetups",
      read: "/meetups/",
      update: "/meetups/:id",
      delete: "/meetups/:id",
    },
    columns: [
      deprecatedRenderColumn("id", columnCopy({ maxLength: 12 })),
      "name",
      // "address",
      "level",
      // renderColumn("notified", columnFormatBoolean),
      deprecatedRenderColumn("applyFrom", columnFormatDate),
      deprecatedRenderColumn("applyTo", columnFormatDate),
      deprecatedRenderColumn("startAt", columnFormatDate),
      "chatLink",
      // renderColumn("createdAt", columnFormatDate),
      // renderColumn("updatedAt", columnFormatDate),
    ],
    datatableOptions: {
      sortOrder: { name: "startAt", direction: "desc" },
    },
    itemUrl: "/meetups/:id",
    // onRowClick: (e) => navigate(`/meetups/${e.id}`),
    itemActions: [
      // {
      //   url: "meetups/:id/games",
      //   icon: <PlaylistPlay />,
      //   actionName: "Edit games",
      //   validationSchema: yup.object().shape({
      //     games: yup.array().of(yup.number().required()).required().meta(
      //       createEntityFieldMeta(EntityFieldType.TEXT_ARRAY, {
      //         // transform: (e: {value: string}) => {
      //         //   return +e.value;
      //         // },
      //       })
      //     ),
      //   }),
      //   defaultValues: (e: Meetup) => {
      //     return { games: e.games?.map((i) => i.geekId) };
      //   },
      // },
    ],
  };
  return { ...sharedProps };
};

// export const MeetupsPage = () => {
//   const { data, isLoading, error } = useFetch<Meetup[]>({ url: "/meetups" });
//   return (
//     <FetchRender data={data} isLoading={isLoading} error={error}>
//       <ListTable columns={[]} data={data} />
//     </FetchRender>
//   );
// };
