import { Chip } from "@mui/material";
import { FeedbackModel } from "@pastukh-dm/wboard-games";
import { depositActionRecord } from "../old/MeetupDepositsTab";

export const FeedbackChip = ({ feedback }: { feedback: FeedbackModel }) => (
  <Chip
    size="small"
    label={feedback.depositAction}
    icon={depositActionRecord[feedback.depositAction].icon}
    variant="outlined"
    sx={{
      borderColor: depositActionRecord[feedback.depositAction].color,
      color: depositActionRecord[feedback.depositAction].color,
      ".MuiChip-icon": {
        color: depositActionRecord[feedback.depositAction].color,
      },
    }}
  />
);
