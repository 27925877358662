import { UserModel } from "@pastukh-dm/wboard-games";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const getUsers = async () => (await axios.get<UserModel[]>("/users")).data

export const useGetUsersQuery = () => {
const { data } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
    staleTime: Infinity,
  });

  return data || [];
};
