import { Button } from "@mui/material";
import { ChipWithMenu } from "src/components/ChipWithMenu";
import { depositStatusRecord } from "../old/MeetupDepositsTab";
import { ApplicationWith, DepositStatus } from "@pastukh-dm/wboard-games";
import { useRequest } from "src/hooks/useRequest";

export const DepositStatusChip = ({
  item,
  onChange,
}: {
  item: ApplicationWith<"deposit">;
  onChange?: () => void;
}) => {
  const { request, isLoading } = useRequest({
    method: "post",
    url: `/deposits`,
  });

  return (
    <>
      {item.deposit ? (
        <ChipWithMenu
          label={item.deposit.status}
          menuItems={Object.values(DepositStatus)}
          menuType="both"
          onClickMenuItem={async (status: string) => {
            await request(
              { status: status },
              {
                method: "patch",
                url: `/deposits/${item.deposit.id}`,
              }
            );
            onChange?.();
          }}
          {...depositStatusRecord[item.deposit.status]}
        />
      ) : (
        <Button
          size="small"
          onClick={async () => {
            await request({
              status: DepositStatus.PENDING,
              applicationId: item.id,
              amount: 20,
            });
            onChange?.();
          }}
        >
          Add deposit
        </Button>
      )}
    </>
  );
};
