import { Table, TableRow, TableCell } from "@mui/material";
import { ReactNode } from "react";

type Props<T> = {
  rows: DetailsTableRow<T>;
  data: T;
};

export type DetailsTableRow<T> = {
  [key in keyof T]?: boolean | ((val: T[key], values: T) => ReactNode);
};

export const DetailsTable = <T,>({ rows, data }: Props<T>) => {
  return (
    <Table>
      {Object.entries(rows).map(([key, value]) => (
        <TableRow>
          <TableCell>{key}</TableCell>
          <TableCell>
            {typeof value === "function"
              ? value(data[key as keyof T], data)
              : data[key as keyof T]}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
