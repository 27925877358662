import * as yup from "yup";
import { Box } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  DeprecatedEntityTable,
  deprecatedRenderColumn,
  columnFormatBoolean,
  columnFormatDate,
} from "../../components/EntityTable/DeprecatedEntityTable";
import { formatDatetime } from "../../helpers/formatHelper";
import {
  deprecatedCreateEntityFieldMeta,
  DeprecatedEntityFieldType,
} from "src/components/EntityTable/DeprecatedEntityForm";
import { Warning } from "@mui/icons-material";

export enum UserRole {
  USER = "USER",
  ADMIN = "ADMIN",
}

export const validationSchema = yup.object({
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  email: yup.string().email().required(),
  name: yup.string().nullable(),
  subscribe: yup.boolean().required(),
  role: yup
    .mixed()
    .oneOf(Object.values(UserRole))
    .required()
    .meta(
      deprecatedCreateEntityFieldMeta(DeprecatedEntityFieldType.AUTOCOMPLETE, {
        options: Object.values(UserRole).map((value) => ({ value })),
      })
    ),
});

export const UsersPage = () => {
  const dateColumn = (name: string) => ({
    name,
    options: {
      customBodyRender: (e: any) => (
        <Box whiteSpace="nowrap">{formatDatetime(e)}</Box>
      ),
    },
  });

  return (
    <>
      <DeprecatedEntityTable
        entityName="user"
        apiUrls={{
          create: "/users",
          read: "/users",
          update: "/users/:id",
          delete: "/users/:id",
        }}
        columns={[
          "id",
          "firstName",
          "lastName",
          "email",
          "name",
          deprecatedRenderColumn("subscribe", columnFormatBoolean),
          "role",
          deprecatedRenderColumn("createdAt", columnFormatDate),
          deprecatedRenderColumn("updatedAt", columnFormatDate),
          deprecatedRenderColumn(
            "hasProblemsWithDeposit",
            (e) =>
              !!e && (
                <Box sx={{ textAlign: "center" }}>
                  <Warning color="warning" />
                </Box>
              ),
            {
              customHeadLabelRender: () => "Problem",
            }
          ),
        ]}
        datatableOptions={{
          sortOrder: { name: "updatedAt", direction: "desc" },
        }}
        itemUrl={`/users/:id`}
        validationSchema={validationSchema as any}
        multipleActions={[
          {
            actionName: "Delete",
            url: "/users",
            method: "DELETE",
            icon: <DeleteForeverIcon />,
          },
        ]}
      />
    </>
  );
};
