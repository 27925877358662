import { Box, Chip, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ApplicationWith, DepositStatus } from "@pastukh-dm/wboard-games";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import { ChipWithMenu } from "src/components/ChipWithMenu";
import { CopyToClipboard } from "src/components/CopyToClipboard";
import { FetchRender } from "src/components/FetchRender";
import { ListTable } from "src/components/ListTable";
import { TotalDeposits } from "src/components/TotalDeposits";
import { formatDatetime } from "src/helpers/formatHelper";
import { useFetch } from "src/hooks/useFetch";
import { useRequest } from "src/hooks/useRequest";
import {
  depositActionRecord,
  depositStatusRecord,
} from "../../meetups/old/MeetupDepositsTab";

export const UserApplicationsTab = () => {
  const { id } = useParams();
  const {
    data: applications,
    error,
    refetch,
    isLoading,
  } = useFetch<ApplicationWith<"deposit" | "meetup" | "feedback">[]>({
    url: `/users/${id}/applications`,
  });

  const { request } = useRequest();
  const navigate = useNavigate();

  return (
    <FetchRender data={applications} error={error} isLoading={isLoading}>
      {applications && (
        <ListTable
          onRowClick={(item) => navigate(`/applications/${item.id}`)}
          columns={[
            {
              label: "Deposit ID",
              render: (item) =>
                item.deposit ? (
                  <CopyToClipboard
                    content={item.deposit?.id}
                    maxLength={8}
                    truncatePosition="middle"
                  />
                ) : (
                  "–"
                ),
            },
            {
              label: "meetup",
              render: (val) => (val.meetup ? val.meetup?.name : ""),
            },
            {
              label: "Deposit Amount",
              render: (item) => <>{item.deposit ? item.deposit.amount : "–"}</>,
            },
            {
              label: "Application Status",
              render: (item) => <>{item.status}</>,
            },
            {
              label: "Rating",
              render: (item) => <>{item.feedback?.rating}</>,
            },
            {
              label: "Feedback",
              render: (item) => (
                <Box sx={{ borderLeft: `3px solid ${grey[200]}`, pl: 1 }}>
                  <i>{item.feedback?.comments}</i>
                </Box>
              ),
            },
            {
              label: "Deposit Feedback",
              render: (item) => (
                <>
                  {item.feedback ? (
                    <Chip
                      size="small"
                      label={item.feedback.depositAction}
                      icon={
                        depositActionRecord[item.feedback.depositAction].icon
                      }
                      variant="outlined"
                      sx={{
                        borderColor:
                          depositActionRecord[item.feedback.depositAction]
                            .color,
                        color:
                          depositActionRecord[item.feedback.depositAction]
                            .color,
                        ".MuiChip-icon": {
                          color:
                            depositActionRecord[item.feedback.depositAction]
                              .color,
                        },
                      }}
                    />
                  ) : (
                    "–"
                  )}
                </>
              ),
            },
            {
              label: "Deposit Status",
              render: (item) => (
                <>
                  {item.deposit ? (
                    <ChipWithMenu
                      label={item.deposit.status}
                      menuItems={Object.values(DepositStatus)}
                      menuType="both"
                      onClickMenuItem={async (status: string) => {
                        await request(
                          { status: status },
                          {
                            method: "patch",
                            url: `/deposits/${item.deposit.id}`,
                          }
                        );
                        refetch();
                      }}
                      {...depositStatusRecord[item.deposit.status]}
                    />
                  ) : (
                    <Button
                      size="small"
                      onClick={async () => {
                        await request(
                          {
                            status: DepositStatus.PENDING,
                            applicationId: item.id,
                            amount: 20,
                          },
                          {
                            method: "post",
                            url: `/deposits`,
                          }
                        );
                        refetch();
                      }}
                    >
                      Add deposit
                    </Button>
                  )}
                </>
              ),
            },
            {
              key: "updatedAt",
              format: (val: any) => (
                <Box sx={{ whiteSpace: "nowrap" }}>{formatDatetime(val)}</Box>
              ),
            },
          ]}
          data={applications.sort((a, b) =>
            dayjs(a.updatedAt).isBefore(dayjs(b.updatedAt)) ? 1 : -1
          )}
        />
      )}

      {applications && <TotalDeposits applications={applications} />}
    </FetchRender>
  );
};
