import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from "@reduxjs/toolkit";
import { generateId, GenerateIdPrefix } from "../../helpers/generateId";
import { RootState } from "../RootState";
import { makeAdapterSelectors } from "../storeHelpers";

export type Application = ApplicationPayload & {
  id: string;
  createdAt: number;
  updatedAt: number;
};

export type ApplicationPayload = {
  createdAt?: number;
  meetupId: string;
  playerId: string;

  status?: ApplicationStatus;
  presenceStatus?: ApplicationPresenceStatus;

  depositAmount?: number;
  donateAmount?: number;
  depositStatus?: ApplicationDepositStatus;

  importNote?: string;

  adminNote?: string;
};
export const DEFAULT_DEPOSIT = 20;
export enum ApplicationStatus {
  NONE = "NONE",

  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  WAITLISTED = "WAITLISTED",

  CANCELED = "CANCELED",
}
export enum ApplicationPresenceStatus {
  JOINED = "JOINED",
  NO_ARRIVAL = "NO_ARRIVAL",
  PENDING = "PENDING",
  NONE = "NONE",
}
export enum ApplicationDepositStatus {
  NONE = "NONE",
  PENDING = "PENDING",
  RECEIVED = "RECEIVED",
  DONATED = "DONATED",
  RETURNED = "RETURNED",
  KEPT_AS_DONATION = "NO_DEPOSIT",
}

const applicationsAdapter = createEntityAdapter<Application>({
  selectId: (application) => application.id,
});

const applicationsSlice = createSlice({
  name: "applications",
  initialState: applicationsAdapter.getInitialState(),
  reducers: {
    applicationCreated(state, action: PayloadAction<ApplicationPayload>) {
      applicationsAdapter.addOne(state, {
        ...action.payload,
        id: generateId(GenerateIdPrefix.Application),
        createdAt: Date.now(),
        updatedAt: Date.now(),
      });
    },

    applicationCreatedMany(state, action: PayloadAction<ApplicationPayload[]>) {
      const players = action.payload.map((item) => ({
        createdAt: Date.now(),
        updatedAt: Date.now(),
        ...item,
        id: generateId(GenerateIdPrefix.Player),
      }));
      applicationsAdapter.addMany(state, players);
    },
    applicationUpdated(state, action: PayloadAction<Update<Application>>) {
      applicationsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload.changes, updatedAt: Date.now() },
      });
    },
    applicationRemoved: applicationsAdapter.removeOne,
    applicationSetAll: applicationsAdapter.setAll,
  },
});

export const applicationsReducer = applicationsSlice.reducer;
export const applicationsActions = applicationsSlice.actions;

export const applicationsSelectors = makeAdapterSelectors(
  applicationsAdapter,
  (state) => state.applications
);

export type ApplicationsState = ReturnType<
  typeof applicationsAdapter.getInitialState
>;
