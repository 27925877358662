import dayjs from "dayjs";
import { truncate } from "lodash";
import { Player, PlayerPayload } from "../store/slices/playersSlice";

export const DATETIME_FORMAT = "DD MMM YYYY, HH:mm";
export const YEARLESS_DATETIME_FORMAT = "DD MMMM, HH:mm";
export const formatDatetime = (e: dayjs.ConfigType) => {
  if (!e) {
    return "-";
  }

  if (dayjs(e).year() === dayjs().year()) {
    return dayjs(e).format(YEARLESS_DATETIME_FORMAT);
  }

  return dayjs(e).format(DATETIME_FORMAT);
};

export const formatId = (e: string) => truncate(e, { length: 13 });
export const formatDescription = (e: string) => truncate(e);

export const getPlayerFullName = (player: Player | PlayerPayload) => {
  return `${player.firstName} ${player.lastName}`;
};
