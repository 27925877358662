import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Box,
  TableContainer,
} from "@mui/material";
import { ReactNode } from "react";

type Props<T> = {
  columns: ListTableColumns<T>;
  data: T[];
  onRowClick?: (data: T) => void;
};
type KeyColumn<T, K extends keyof T> = {
  key: K;
  label?: string;
  format?: (val: T[K]) => ReactNode;
};

export type ListTableColumns<T> = Array<ListTableColumn<T>>;

export type ListTableColumn<T> =
  | keyof T
  | { [K in keyof T]: KeyColumn<T, K> }[keyof T]
  | CustomColumn<T>
  | ActionColumn<T>;

type ActionColumn<T> = { action: (val: T) => ReactNode };
type CustomColumn<T> = {
  label?: string;
  render: (val: T) => ReactNode;
  width?: number;
};

function isStringColumn<T>(value: ListTableColumn<T>): value is keyof T {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "symbol"
  );
}
function isKeyColumn<T>(
  value: ListTableColumn<T>
): value is KeyColumn<T, keyof T> {
  return typeof value === "object" && "key" in value;
}
function isCustomColumn<T>(
  value: ListTableColumn<T>
): value is CustomColumn<T> {
  return typeof value === "object" && "render" in value;
}
function isActionColumn<T>(
  value: ListTableColumn<T>
): value is ActionColumn<T> {
  return typeof value === "object" && "action" in value;
}

export const ListTable = <T,>({ columns, data, onRowClick }: Props<T>) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((column, columnKey) => (
              <TableCell
                key={columnKey}
                sx={
                  isActionColumn(column) ? { width: 0, padding: 0 } : undefined
                }
              >
                {isStringColumn(column)
                  ? (column as ReactNode)
                  : isKeyColumn(column)
                  ? (column.label as ReactNode) || (column.key as ReactNode)
                  : isCustomColumn(column)
                  ? (column.label as ReactNode) || ""
                  : ""}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, dataKey) => (
            <TableRow
              key={dataKey}
              hover={!!onRowClick}
              sx={{ cursor: !!onRowClick ? "pointer" : undefined }}
            >
              {columns.map((column, columnKey) => (
                <TableCell
                  key={columnKey}
                  sx={{
                    ...(isActionColumn(column)
                      ? { width: 0, padding: 0 }
                      : isCustomColumn(column)
                      ? {
                          width: column.width ? `${column.width}px` : undefined,
                        }
                      : {}),
                  }}
                  onClick={
                    !!onRowClick && !isActionColumn(column)
                      ? () => onRowClick(item)
                      : undefined
                  }
                >
                  {isStringColumn(column)
                    ? (item[column] as ReactNode)
                    : isKeyColumn(column)
                    ? column.format
                      ? (column.format(item[column.key]) as ReactNode)
                      : (item[column.key] as ReactNode)
                    : isActionColumn(column)
                    ? column.action(item)
                    : isCustomColumn(column)
                    ? column.render(item)
                    : "PROBLEM"}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
