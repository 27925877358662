import { Box, Chip, Typography } from "@mui/material";
import {
  ApplicationWith,
  GamesOnMeetupsWith,
  MeetupModel,
  UserModel,
} from "@pastukh-dm/wboard-games";
import { uniqBy } from "lodash";
import { ListTable } from "src/components/ListTable";
import { useFetch } from "src/hooks/useFetch";

type Props = { meetup: MeetupModel };

export const MeetupPlanFinalTab = ({ meetup }: Props) => {
  const { data: meetupGames } = useFetch<GamesOnMeetupsWith<"game">[]>({
    url: `/meetups/${meetup.id}/games`,
  });
  const { data: applications, refetch: refetchApplications } = useFetch<
    ApplicationWith<"user">[]
  >({
    url: `/meetups/${meetup.id}/applications`,
  });
  const assignedApplications = applications?.filter((a) => !!a.assignedGameId);

  const cooldownGames = (meetupGames || [])
    .filter((g) => !g.isMainGame && g.isBrought)
    .sort((a, b) => (a.game!.weight! > b.game!.weight! ? -1 : 1));
  const mainGames = (meetupGames || [])
    .filter((g) => g.isMainGame && g.isBrought)
    .sort((a, b) => (a.game!.weight! > b.game!.weight! ? -1 : 1));

  const getChipName = (user?: UserModel) =>
    user
      ? `${user?.name} (${user?.email.split("@")[0].slice(0, 2)}..${user?.email
          .split("@")[0]
          .slice(3, 5)})`
      : null;

  return (
    <>
      <Typography variant="h5" sx={{ my: 2 }}>
        Main games
      </Typography>
      {mainGames && (
        <Box sx={{}}>
          <ListTable
            columns={[
              {
                label: "GeekID",
                width: 100,
                render: (item) => item.game.geekId,
              },
              { label: "Name", width: 250, render: (item) => item.game.name },
              {
                label: "Weight",
                width: 100,
                render: (item) => item.game.weight?.toFixed(2),
              },
              {
                label: "Players count",
                width: 100,
                render: (item) => {
                  const count = uniqBy(
                    assignedApplications?.filter(
                      (a) => a.assignedGameId === item.gameId
                    ),
                    (a) => `${a.id}${a.assignedGameId}`
                  ).length;
                  const max = item.game.maxPlayers;
                  return (
                    <Box
                      sx={{
                        color:
                          count === max
                            ? "primary.main"
                            : count > max
                            ? "error.main"
                            : undefined,
                        fontWeight:
                          count === max || count > max ? 800 : undefined,
                      }}
                    >
                      {count}/{max}
                    </Box>
                  );
                },
              },
              {
                label: "Assigned",
                render: (item) => (
                  <>
                    {uniqBy(
                      assignedApplications?.filter(
                        (a) => a.assignedGameId === item.gameId
                      ),
                      (a) => `${a.id}${a.assignedGameId}`
                    ).map((a) => (
                      <Chip
                        size="small"
                        label={getChipName(a.user)}
                        variant="outlined"
                        sx={{
                          m: 0.5,
                        }}
                      />
                    ))}
                  </>
                ),
              },
            ]}
            data={mainGames}
          />
        </Box>
      )}
      <Typography variant="h5" sx={{ my: 2 }}>
        Cooldown games
      </Typography>
      {cooldownGames && (
        <Box
          sx={
            {
              /* width: "700px"  */
            }
          }
        >
          <ListTable
            columns={[
              {
                label: "GeekID",
                width: 100,
                render: (item) => item.game.geekId,
              },
              { label: "Name", width: 250, render: (item) => item.game.name },
              {
                label: "Weight",
                width: 100,
                render: (item) => item.game.weight?.toFixed(2),
              },
              {
                label: "Max. players",
                width: 100,
                render: (item) => {
                  const count = uniqBy(
                    assignedApplications?.filter(
                      (a) => a.assignedGameId === item.gameId
                    ),
                    (a) => `${a.id}${a.assignedGameId}`
                  ).length;
                  const max = item.game.maxPlayers;
                  return (
                    <Box
                      sx={{
                        color:
                          count === max
                            ? "primary.main"
                            : count > max
                            ? "error.main"
                            : undefined,
                        fontWeight:
                          count === max || count > max ? 800 : undefined,
                      }}
                    >
                      {max}
                    </Box>
                  );
                },
              },
              {
                render: () => <></>,
              },
            ]}
            data={cooldownGames}
          />
        </Box>
      )}
    </>
  );
};
