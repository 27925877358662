import { Alert, Box, Switch, Typography } from "@mui/material";
import { ApplicationWithRelations } from "@pastukh-dm/wboard-games";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { AppTabs } from "../../components/AppTabs";
import { CopyToClipboard } from "../../components/CopyToClipboard";
import { DetailsTable } from "../../components/DetailsTable";
import { FetchRender } from "../../components/FetchRender";
import { ListTable } from "../../components/ListTable";
import { PageTitle } from "../../components/PageTitle";
import { formatDatetime } from "../../helpers/formatHelper";
import { useFetch } from "../../hooks/useFetch";

export const ApplicationDetailsPage = () => {
  const { id } = useParams();
  const {
    data: application,
    error,
    isLoading,
  } = useFetch<ApplicationWithRelations>({ url: `/applications/${id}/full` });

  return (
    <>
      <PageTitle
        text={
          application
            ? `Application: ${application.meetup.name} • ${application.user.email}`
            : `Application: ${id}`
        }
      />
      <FetchRender data={application} error={error} isLoading={isLoading}>
        {application && (
          <AppTabs
            tabs={[
              {
                key: "application",
                content: (
                  <>
                    <DetailsTable
                      rows={{
                        id: true,
                        user: (val) => (
                          <a href={`/users/${val.id}`}>
                            <b>{val.name}</b> ({val.email})
                          </a>
                        ),
                        meetup: (val) => (
                          <a href={`/meetups/${val.id}`}>
                            <b>{val.name}</b> ({formatDatetime(val.startAt)})
                          </a>
                        ),
                        createdAt: (val) => formatDatetime(val),
                        updatedAt: (val) => formatDatetime(val),
                        status: true,
                        deposit: (val) => <>{val?.status}</>,
                        transferMethod: true,
                        transferAt: (val) => formatDatetime(val),
                        validUntil: (val) => formatDatetime(val),
                        cancelReason: true,
                        highlighted: (val) => (
                          <Switch checked={!!val} disabled />
                        ),
                        addedGamesString: true,
                      }}
                      data={application}
                    />
                  </>
                ),
              },

              {
                key: "emails",
                count: application.userEmails.length,
                content: (
                  <>
                    <ListTable
                      columns={[
                        {
                          key: "id",
                          format: (val) => (
                            <CopyToClipboard content={val} maxLength={16} />
                          ),
                        },
                        {
                          key: "messageId",
                          format: (val) => (
                            <CopyToClipboard content={val} maxLength={16} />
                          ),
                        },
                        "template",
                        "error",
                        {
                          key: "updatedAt",
                          format: (val) => (
                            <Box sx={{ whiteSpace: "nowrap" }}>
                              {formatDatetime(val)}
                            </Box>
                          ),
                        },
                      ]}
                      // template: string
                      // templateParams: string | null
                      // provider: string | null
                      // messageId: string | null
                      // error: string | null
                      // userId: string
                      // applicationId: string | null
                      data={application.userEmails.sort((a, b) =>
                        dayjs(a.updatedAt).isBefore(dayjs(b.updatedAt)) ? 1 : -1
                      )}
                    />
                  </>
                ),
              },
            ]}
          />
        )}
      </FetchRender>
    </>
  );
};
