import { History } from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardContent,
  Button,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";

export const ShowPastMeetupsCard = ({ onClick }: { onClick: () => void }) => {
  const theme = useTheme();
  return (
    <Card
      variant="outlined"
      sx={{ height: "100%", background: grey[50], borderColor: grey[50] }}
    >
      <CardActionArea
        sx={{
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
        onClick={onClick}
      >
        <CardContent>
          <Button
            startIcon={<History />}
            sx={{ color: theme.palette.grey[500] }}
          >
            Past meetups
          </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
