import { Alert, CircularProgress } from "@mui/material";
import { PropsWithChildren } from "react";
import { FetchOnceResult } from "../hooks/useFetch";

type Props<T> = Omit<FetchOnceResult<T>, "refetch">;

export const FetchRender = <T,>({
  isLoading,
  error,
  data,
  children,
}: PropsWithChildren<Props<T>>) => {
  if (isLoading) {
    return <CircularProgress/>;
  }

  if (error) {
    return <Alert severity="error">Error: {error.message}</Alert>;
  }
  if (data === null) {
    return <Alert severity="warning">No data</Alert>;
  }
  return <>{children}</>;
};
