import { Box, Button } from "@mui/material";
import axios from "axios";

export const BackupButton = () => {
  const { onBackup } = useOnBackup();
  return (
    <Box sx={{ mb: 4, textAlign: "right" }}>
      <Button
        variant="outlined"
        size="small"
        color="warning"
        onClick={onBackup}
      >
        Backup
      </Button>
    </Box>
  );
};

export const useOnBackup = () => {
  const onBackup = async () => {
    const res = await axios.post("/backup", undefined, {
      responseType: "blob",
      withCredentials: false,
    });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `database-${process.env.REACT_APP_ENVIRONMENT}-${Date.now()}.sqlite`
    );
    document.body.appendChild(link);
    link.click();
  };
  return { onBackup };
};
