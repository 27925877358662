import {
  deprecatedCreateEntityFieldMeta,
  DeprecatedEntityFieldType,
  DeprecatedEntityFieldSelectOption,
} from "../components/EntityTable/DeprecatedEntityForm";
import * as yup from "yup";
import { ApplicationStatus } from "@pastukh-dm/wboard-games/lib";

export const createApplicationValidator = ({
  meetupOptions,
  userOptions,
}: {
  meetupOptions: DeprecatedEntityFieldSelectOption[];
  userOptions: DeprecatedEntityFieldSelectOption[];
}) => {
  return yup.object({
    meetupId: yup
      .string()
      .oneOf(meetupOptions.map((i) => i.value))
      .required()
      .meta(
        deprecatedCreateEntityFieldMeta(
          DeprecatedEntityFieldType.AUTOCOMPLETE,
          {
            options: meetupOptions,
          }
        )
      ),
    userId: yup
      .string()
      .oneOf(userOptions.map((i) => i.value))
      .required()
      .meta(
        deprecatedCreateEntityFieldMeta(
          DeprecatedEntityFieldType.AUTOCOMPLETE,
          {
            options: userOptions,
          }
        )
      ),
    status: yup
      .string()
      .oneOf(Object.values(ApplicationStatus))
      .required()
      .meta(
        deprecatedCreateEntityFieldMeta(
          DeprecatedEntityFieldType.AUTOCOMPLETE,
          {
            options: Object.values(ApplicationStatus).map((value) => ({
              value,
            })),
          }
        )
      ),
    transferMethod: yup.string().nullable(),
    games: yup.string().nullable(),
  });
};
