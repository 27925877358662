import { Backdrop, CircularProgress } from "@mui/material";

export const BackdropLoading = ({ show }: { show: boolean }) => {
  return (
    <Backdrop
      sx={{
        color: "#000",
        background: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={show}
    >
      <CircularProgress
        sx={{ color: (theme) => theme.palette.primary.dark }}
        size="15vmin"
      />
    </Backdrop>
  );
};
