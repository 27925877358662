import {
  Favorite,
  School,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Checkbox,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import {
  ApplicationModel,
  ApplicationStatus,
  ApplicationWith,
  GameModel,
  GamesOnMeetupsWith,
  GameVoteExperience,
  GameVoteInterest,
  GameVoteModel,
  GameVoteWith,
  MeetupModel,
  UserModel,
} from "@pastukh-dm/wboard-games";
import { uniqBy } from "lodash";
import { useState, useMemo, useEffect } from "react";
import { ListTable } from "src/components/ListTable";
import { useFetch } from "src/hooks/useFetch";
import { useRequest } from "src/hooks/useRequest";

type Props = { meetup: MeetupModel };

type FetchedGameVote = GameVoteModel & { application: ApplicationWith<"user"> };

export const MeetupPlanMainTab = ({ meetup }: Props) => {
  const { data: meetupGames } = useFetch<GamesOnMeetupsWith<"game">[]>({
    url: `/meetups/${meetup.id}/games`,
  });
  const { data: applications, refetch: refetchApplications } = useFetch<
    ApplicationWith<"user">[]
  >({
    url: `/meetups/${meetup.id}/applications`,
  });
  const { data: gameVotes, refetch: refetchVotes } = useFetch<
    FetchedGameVote[]
  >({
    url: `/meetups/${meetup.id}/votes`,
  });
  const assignedApplications = applications?.filter((a) => !!a.assignedGameId);
  const [focusedApplicationId, setFocusedApplicationId] = useState<
    string | null
  >(null);
  const notAssignedVotes = gameVotes?.filter(
    (v) =>
      (v.interest !== GameVoteInterest.NEUTRAL ||
        v.experience !== GameVoteExperience.NO) &&
      (!v.application.assignedGameId ||
        v.applicationId === focusedApplicationId)
  );
  const STORAGE_KEY = `hideGameIds_${meetup.id}`;

  const [storedIds, setStoredIds] = useState<string[]>(
    JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]")
  );
  const visibleGames = useMemo(
    () =>
      (meetupGames || []).filter((item) => !storedIds.includes(item.gameId)),
    [storedIds, meetupGames]
  );

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storedIds));
  }, [storedIds]);

  const refetch = () => {
    refetchVotes();
    refetchApplications();
  };

  const { request } = useRequest();

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [currentContext, setCurrentContext] = useState<ApplicationModel>();
  const handleContextMenu =
    (application: ApplicationModel) => (event: React.MouseEvent) => {
      event.preventDefault();
      setCurrentContext(application);
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : null
      );
    };
  const handleClose = () => {
    setContextMenu(null);
  };
  const handleContextClick = async (game: GameModel) => {
    await request(
      { assignedGame: game.id },
      { url: `/applications/${currentContext?.id}/mainGame` }
    );
    refetch();
    setContextMenu(null);
  };
  const applicationsWithoutVotes = (applications || []).filter(
    (a) =>
      [
        ApplicationStatus.VOTING,
        ApplicationStatus.DEPOSITED,
        ApplicationStatus.VOTED,
        ApplicationStatus.GAME_ASSIGNED,
      ].includes(a.status) && !a.assignedGameId
  );

  const renderVoter =
    (
      color?: "primary" | "success" | "error" | "secondary",
      variant?: "filled" | "outlined"
    ) =>
    (v: FetchedGameVote) =>
      (
        <PlayerChip
          color={color}
          variant={variant}
          user={v.application.user}
          focused={
            focusedApplicationId !== null &&
            focusedApplicationId !== v.applicationId
          }
          onClick={async () => {
            await request(
              { assignedGame: v.gameId },
              { url: `/applications/${v.applicationId}/mainGame` }
            );
            refetch();
          }}
          onContextMenu={handleContextMenu(v.application)}
          onMouseEnter={() => setFocusedApplicationId(v.applicationId)}
          onMouseLeave={() => setFocusedApplicationId(null)}
        />
      );

  const mainGames = useMemo(
    () =>
      visibleGames
        .filter((g) => g.isMainGame)
        .sort((a, b) => (a.game!.weight! > b.game!.weight! ? -1 : 1)),
    [visibleGames]
  );

  return (
    <>
      {mainGames && (
        <>
          <Typography variant="body2" sx={{ mx: 0.5 }}>
            Assigned:{" "}
            <b>
              {applications?.filter((a) => !!a.assignedGameId).length}/
              {
                applications?.filter((a) =>
                  [
                    ApplicationStatus.VOTED,
                    ApplicationStatus.VOTING,
                    ApplicationStatus.GAME_ASSIGNED,
                  ].includes(a.status)
                ).length
              }
            </b>
          </Typography>
          <Box sx={{ my: 2 }}>
            {applicationsWithoutVotes
              .sort((a, b) =>
                gameVotes &&
                gameVotes?.filter(
                  (v) =>
                    v.application.id === a.id &&
                    (v.interest === GameVoteInterest.LIKE ||
                      v.interest === GameVoteInterest.LOVE)
                ).length >
                  gameVotes?.filter(
                    (v) =>
                      v.application.id === b.id &&
                      (v.interest === GameVoteInterest.LIKE ||
                        v.interest === GameVoteInterest.LOVE)
                  ).length
                  ? -1
                  : 1
              )
              .map((a) => (
                <PlayerChip
                  user={a.user}
                  onClick={handleContextMenu(a)}
                  onContextMenu={handleContextMenu(a)}
                  badge={` ♦︎ ${
                    gameVotes?.filter(
                      (v) =>
                        v.application.id === a.id &&
                        v.interest === GameVoteInterest.LOVE
                    ).length
                  } ♥︎${
                    gameVotes?.filter(
                      (v) =>
                        v.application.id === a.id &&
                        v.interest === GameVoteInterest.LIKE
                    ).length
                  }`}
                />
              ))}
          </Box>
          {storedIds.length > 0 && (
            <Typography variant="body2" sx={{ my: 1, mx: 0.5 }}>
              Hidden:
              <IconButton onClick={() => setStoredIds([])}>
                <Badge badgeContent={storedIds.length}>
                  <VisibilityOff />
                </Badge>
              </IconButton>
            </Typography>
          )}
          <ListTable
            columns={[
              {
                label: "Hide",
                render: (item) => (
                  <IconButton
                    onClick={() => setStoredIds([...storedIds, item.gameId])}
                  >
                    <VisibilityOff />
                  </IconButton>
                ),
              },
              {
                label: "Brought",
                render: (item) => (
                  <>
                    <Checkbox
                      defaultChecked={item.isBrought}
                      onChange={async (e, checked) => {
                        await request(
                          { isBrought: checked },
                          {
                            method: "patch",
                            url: `/meetups/${meetup.id}/games/${item.gameId}`,
                          }
                        );
                        refetch();
                      }}
                    />
                  </>
                ),
              },
              { label: "GeekID", render: (item) => item.game.geekId },
              { label: "Name", render: (item) => item.game.name },
              {
                label: "Weight",
                render: (item) => item.game.weight?.toFixed(2),
              },
              {
                label: "Players count",
                render: (item) => {
                  const count = uniqBy(
                    assignedApplications?.filter(
                      (a) => a.assignedGameId === item.gameId
                    ),
                    (a) => `${a.id}${a.assignedGameId}`
                  ).length;
                  const max = item.game.maxPlayers;
                  return (
                    <Box
                      sx={{
                        color:
                          count === max
                            ? "primary.main"
                            : count > max
                            ? "error.main"
                            : undefined,
                        fontWeight:
                          count === max || count > max ? 800 : undefined,
                      }}
                    >
                      {count}/{max}
                    </Box>
                  );
                },
              },
              {
                label: "Assigned",
                render: (item) => (
                  <>
                    {uniqBy(
                      assignedApplications?.filter(
                        (a) => a.assignedGameId === item.gameId
                      ),
                      (a) => `${a.id}${a.assignedGameId}`
                    ).map((a) => (
                      <PlayerChip
                        user={a.user}
                        onContextMenu={handleContextMenu(a)}
                        onClick={async () => {
                          await request(
                            {},
                            {
                              url: `/applications/${a.id}/unsetMainGame`,
                            }
                          );
                          refetch();
                        }}
                      />
                    ))}
                  </>
                ),
              },
              {
                label: "Know & Love",
                render: (item) =>
                  notAssignedVotes
                    ?.filter(
                      (v) =>
                        v.gameId === item.gameId &&
                        v.experience === GameVoteExperience.YES &&
                        v.interest === GameVoteInterest.LOVE
                    )
                    .map(renderVoter("secondary", "filled")),
              },
              {
                label: "Know & Like",
                render: (item) =>
                  notAssignedVotes
                    ?.filter(
                      (v) =>
                        v.gameId === item.gameId &&
                        v.experience === GameVoteExperience.YES &&
                        v.interest === GameVoteInterest.LIKE
                    )
                    .map(renderVoter("success", "filled")),
              },
              {
                label: "Love",
                render: (item) =>
                  notAssignedVotes
                    ?.filter(
                      (v) =>
                        v.gameId === item.gameId &&
                        v.experience === GameVoteExperience.NO &&
                        v.interest === GameVoteInterest.LOVE
                    )
                    .map(renderVoter("secondary", "outlined")),
              },
              {
                label: "Like",
                render: (item) =>
                  notAssignedVotes
                    ?.filter(
                      (v) =>
                        v.gameId === item.gameId &&
                        v.experience === GameVoteExperience.NO &&
                        v.interest === GameVoteInterest.LIKE
                    )
                    .map(renderVoter("success", "outlined")),
              },
              {
                label: "Dislike",
                render: (item) =>
                  notAssignedVotes
                    ?.filter(
                      (v) =>
                        v.gameId === item.gameId &&
                        v.interest === GameVoteInterest.DISLIKE
                    )
                    .map(renderVoter("error", "outlined")),
              },
            ]}
            data={mainGames}
          />
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? {
                    top: contextMenu.mouseY,
                    left: contextMenu.mouseX,
                  }
                : undefined
            }
          >
            {mainGames.map((g) => {
              const foundVote = gameVotes?.find(
                (v) =>
                  v.application.id === currentContext?.id &&
                  v.gameId === g.gameId
              );
              return (
                // <MenuItem
                //   dense
                //   onClick={() => handleContextClick(g.game)}
                //   disabled={currentContext?.assignedGameId === g.gameId}
                // >
                //   {g.game.name}
                // </MenuItem>

                <MenuItem
                  dense
                  onClick={() => handleContextClick(g.game)}
                  disabled={currentContext?.assignedGameId === g.gameId}
                >
                  <ListItemText>{g.game.name}</ListItemText>

                  {foundVote?.experience === GameVoteExperience.YES && (
                    <ListItemIcon sx={{ m: 0, p: 0, ml: 2, mr: -2 }}>
                      <School color="info" />
                    </ListItemIcon>
                  )}
                  {foundVote?.interest === GameVoteInterest.LOVE && (
                    <ListItemIcon sx={{ m: 0, p: 0, ml: 2, mr: -2 }}>
                      <Favorite color="secondary" />
                    </ListItemIcon>
                  )}
                  {foundVote?.interest === GameVoteInterest.LIKE && (
                    <ListItemIcon sx={{ m: 0, p: 0, ml: 2, mr: -2 }}>
                      <ThumbUpOutlined color="success" />
                    </ListItemIcon>
                  )}
                  {foundVote?.interest === GameVoteInterest.DISLIKE && (
                    <ListItemIcon sx={{ m: 0, p: 0, ml: 2, mr: -2 }}>
                      <ThumbDownOutlined color="error" />
                    </ListItemIcon>
                  )}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </>
  );
};

const getChipName = (user?: UserModel) =>
  user ? `${user?.name} (${user?.email.split("@")[0].slice(0, 6)})` : null;

const PlayerChip = ({
  color,
  variant,
  user,
  focused,
  onClick,
  onContextMenu,
  onMouseEnter,
  onMouseLeave,
  badge,
}: {
  color?: "primary" | "error" | "secondary" | "success";
  variant?: "filled" | "outlined";
  user?: UserModel;
  focused?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  badge?: string;
}) => (
  <Chip
    size="small"
    color={color}
    variant={variant}
    label={`${getChipName(user)}${badge || ""}`}
    sx={{
      m: 0.5,
      transition: "0.5s",
      opacity: focused ? 0.25 : 1,
    }}
    onClick={onClick}
    onContextMenu={onContextMenu}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  />
);
