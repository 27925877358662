import { useState, useEffect } from "react";
import axios, { AxiosRequestConfig, AxiosError } from "axios";

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE";

export interface FetchOnceOptions<T> {
  url?: string;
  method?: RequestMethod;
  payload?: T;
}

export interface FetchOnceResult<T> {
  data: T | null;
  isLoading: boolean;
  error: AxiosError | null;
  refetch: () => void;
}

export const useFetch = <T>(
  options: FetchOnceOptions<T>
): FetchOnceResult<T> => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!options.url) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);

      const config: AxiosRequestConfig = {
        url: options.url,
        method: options.method || "GET",
        headers: { "Content-Type": "application/json" },
        data: options.payload,
      };

      try {
        const response = await axios(config);

        if (isMounted) {
          setData(response.data);
          setIsLoading(false);
        }
      } catch (error: any) {
        if (isMounted) {
          setError(error);
          setIsLoading(false);
        }
      }
    };

    if (shouldRefetch) {
      fetchData();
      setShouldRefetch(false);
    } else {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [options.url, options.method, options.payload, shouldRefetch]);

  const refetch = () => {
    setShouldRefetch(true);
  };

  return { data, isLoading, error, refetch };
};
