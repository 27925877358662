import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { MeetupWithRelations } from "@pastukh-dm/wboard-games";
import dayjs from "dayjs";
import { useState } from "react";
import { formatDatetime } from "src/helpers/formatHelper";
import { useFetch } from "src/hooks/useFetch";
import { AddMeetupCard } from "./components/AddMeetupCard";
import { MeetupCard } from "./components/MeetupCard";
import { ShowPastMeetupsCard } from "./components/ShowPastMeetupsCard";

export const MeetupsPage = () => {
  const [searchTerm, setSearchTerm] = useState(""); // State to hold the search term

  const { data, isLoading } = useFetch<MeetupWithRelations[]>({
    url: "/meetups",
  }); // Filter the data based on the search term
  const activeData = data?.filter(
    (item) => !dayjs(item.startAt).isBefore(dayjs().subtract(1, "month"))
  );
  const searchedData =
    searchTerm &&
    data?.filter((item) =>
      JSON.stringify([item.name, item.startAt, formatDatetime(item.startAt)])
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

  const [showPast, setShowPast] = useState(false);

  const visibleData = searchedData || (showPast ? data : activeData);

  return (
    <>
      <Typography variant="h1" sx={{ mb: 2 }}>
        Meetups
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: 4 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="caption">
                    {searchTerm ? (
                      <span>{searchedData?.length} item(s) found</span>
                    ) : (
                      <span>{data?.length} item(s)</span>
                    )}
                  </Typography>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            {visibleData
              ?.sort((a, b) => (a.startAt > b.startAt ? -1 : 1))
              .map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <MeetupCard data={item} />
                </Grid>
              ))}

            {!searchTerm && !showPast && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ShowPastMeetupsCard onClick={() => setShowPast(true)} />
              </Grid>
            )}
            {!searchTerm && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <AddMeetupCard />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};
