import { customAlphabet } from "nanoid";
import { alphanumeric } from "nanoid-dictionary";

const alphanumericRandomString = customAlphabet(alphanumeric, 10);

export enum GenerateIdPrefix {
  Application = "AP",
  Meetup = "MT",
  Player = "PL",
  Vote = "VT",
  Feedback = "FB",
  Game = "GM",
}
export const generateId = (prefix?: GenerateIdPrefix) => {
  const id = alphanumericRandomString();
  return prefix ? `${prefix}.${id}` : id;
};
