import { MoreVert } from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { ApplicationModel, MeetupModel } from "@pastukh-dm/wboard-games";
import { useState } from "react";
import { FormDialog } from "src/components/dialogs/FormDialog";
import { MaterialColor } from "src/types/MaterialColor";
import {
  MenuAction,
  useMeetupApplicationMenu,
} from "../hooks/useMeetupApplicationMenu";
import { useGetUsersQuery } from "src/hooks/fetchers/getUsers";

export const MeetupApplicationMenuButton = ({
  application,
  meetup,
  refetch,
  allMeetups,
}: {
  application: ApplicationModel;
  meetup: MeetupModel;
  refetch: () => void;
  allMeetups: MeetupModel[];
}) => {
  const users = useGetUsersQuery();
  const { menuItems, dialogParams, action, setAction } =
    useMeetupApplicationMenu({
      application,
      meetup,
      allUsers: users,
      allMeetups,
    });

  // menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // dialog
  const handleAction = (newAction: MenuAction) => {
    setAction(newAction);
    setAnchorEl(null);
  };

  const openDialog = Boolean(action);

  const handleCloseDialog = () => {
    setAction(MenuAction.NONE);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={handleMenuClick}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.action}
            onClick={() => handleAction(item.action)}
            disabled={item.disabled}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <FormDialog
        open={openDialog}
        title={dialogParams.title}
        fields={dialogParams.fields}
        initialValues={dialogParams.initialValues}
        submitMethod={dialogParams.submitMethod}
        submitUrl={dialogParams.submitUrl}
        submitText={dialogParams.submitText}
        submitColor={MaterialColor.PRIMARY}
        onSuccess={refetch}
        onClose={handleCloseDialog}
      />
    </Box>
  );
};
