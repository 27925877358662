import { DialogActions, Button, ButtonProps, DialogProps } from "@mui/material";
import { useEffect, useState } from "react";
import { AppBaseDialog } from "./AppBaseDialog";

export type AppDialogProps = DialogProps & {
  title: React.ReactNode;
  onSubmit?: () => void;
  content?: React.ReactNode;
  onClose?: () => void;
  closeButton?: { props?: ButtonProps; text?: string };
  submitButton?: { props?: ButtonProps; text?: string };
};

export const AppDialog = ({
  title,
  onSubmit,
  content,
  onClose,
  closeButton,
  submitButton,
  ...dialogProps
}: AppDialogProps) => {
  const [open, setOpen] = useState(dialogProps.open);

  useEffect(() => {
    setOpen(dialogProps.open);
  }, [dialogProps.open]);

  function handleClose() {
    // setOpen(false);
    onClose?.();
  }
  function handleSubmit() {
    onSubmit?.();
    // setOpen(false);
  }
  return (
    <AppBaseDialog
      {...dialogProps}
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      title={title}
      content={content}
      actions={
        <DialogActions>
          <Button {...closeButton?.props} onClick={handleClose}>
            {closeButton?.text || "Close"}
          </Button>
          <Button
            color="primary"
            variant="contained"
            {...submitButton?.props}
            onClick={handleSubmit}
          >
            {submitButton?.text || "Submit"}
          </Button>
        </DialogActions>
      }
    />
  );
};
