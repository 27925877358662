import { useContext } from "react";
import { LayoutNotificationContext } from "../layout/LayoutNotification";

export const useNotification = () => {
  const { showNotification } = useContext(LayoutNotificationContext);
  const showErrorNotification = (e: any) => {
    showNotification({
      severity: "error",
      message:
        e?.response?.data?.error ||
        e?.response?.data?.message ||
        e?.response?.statusText,
    });
  };
  return { showNotification, showErrorNotification };
};
