import { Alert, Box, Switch } from "@mui/material";
import { UserFull } from "@pastukh-dm/wboard-games";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { AppTabs } from "../../components/AppTabs";
import { CopyToClipboard } from "../../components/CopyToClipboard";
import { DetailsTable } from "../../components/DetailsTable";
import { FetchRender } from "../../components/FetchRender";
import { ListTable } from "../../components/ListTable";
import { PageTitle } from "../../components/PageTitle";
import { formatDatetime } from "../../helpers/formatHelper";
import { useFetch } from "../../hooks/useFetch";
import { UserApplicationsTab } from "./components/UserApplicationsTab";
import { UserGamesTab } from "./components/UserGamesTab";

export const UserDetailsPage = () => {
  const { id } = useParams();
  const {
    data: user,
    error,
    isLoading,
  } = useFetch<UserFull & { hasProblemsWithDeposit: boolean }>({
    url: `/users/${id}/full`,
  });

  return (
    <>
      <PageTitle
        text={user ? `User: ${user.name} • ${user.email}` : `User: ${id}`}
      />
      {user?.hasProblemsWithDeposit && (
        <Alert color="warning">hasProblemsWithDeposit</Alert>
      )}
      <FetchRender data={user} error={error} isLoading={isLoading}>
        {user && (
          <AppTabs
            tabs={[
              {
                key: "user",
                content: (
                  <>
                    <DetailsTable
                      rows={{
                        id: true,
                        name: true,
                        email: true,
                        firstName: true,
                        lastName: true,
                        subscribe: (val) => <Switch checked={!!val} disabled />,
                        // user: (val) => (
                        //   <a href={`/users/${val.id}`}>
                        //     <b>{val.name}</b> ({val.email})
                        //   </a>
                        // ),
                        // meetup: (val) => (
                        //   <>
                        //     <b>{val.name}</b> ({formatDatetime(val.startAt)})
                        //   </>
                        // ),
                        createdAt: (val) => formatDatetime(val),
                        updatedAt: (val) => formatDatetime(val),
                        // status: true,
                        // deposit: (val) => <>{val?.status}</>,
                        // transferMethod: true,
                        // transferAt: (val) => formatDatetime(val),
                        // validUntil: (val) => formatDatetime(val),
                        // cancelReason: true,
                        // highlighted: (val) => <Switch value={val} disabled />,
                        // addedGamesString: (val) => (
                        //   <>{val ? JSON.parse(val).join(", ") : null}</>
                        // ),
                        // userEmails: UserEmailModel;
                        // gameVotes: GameVoteModel[];
                        // feedback: FeedbackModel[];
                        // addedGames: GameModel[];
                      }}
                      data={user}
                    />
                  </>
                ),
              },
              {
                key: "applications",
                count: user.applications.length,
                content: <UserApplicationsTab />,
              },
              {
                key: "emails",
                count: user.userEmails.length,
                content: (
                  <>
                    <ListTable
                      columns={[
                        {
                          key: "id",
                          format: (val) => (
                            <CopyToClipboard content={val} maxLength={16} />
                          ),
                        },
                        {
                          key: "messageId",
                          format: (val) => (
                            <CopyToClipboard content={val} maxLength={16} />
                          ),
                        },
                        "template",
                        "error",
                        {
                          key: "updatedAt",
                          format: (val) => (
                            <Box sx={{ whiteSpace: "nowrap" }}>
                              {formatDatetime(val)}
                            </Box>
                          ),
                        },
                      ]}
                      // template: string
                      // templateParams: string | null
                      // provider: string | null
                      // messageId: string | null
                      // error: string | null
                      // userId: string
                      // applicationId: string | null
                      data={user.userEmails.sort((a, b) =>
                        dayjs(a.updatedAt).isBefore(dayjs(b.updatedAt)) ? 1 : -1
                      )}
                    />
                  </>
                ),
              },
              {
                key: "games",
                count: user.games.length,
                content: <UserGamesTab />,
              },
            ]}
          />
        )}
      </FetchRender>
    </>
  );
};
