import { ApplicationStatus } from "@pastukh-dm/wboard-games/lib";

// Define the allowed status transitions
export const StatusFlow: { [key in ApplicationStatus]: ApplicationStatus[] } = {
  [ApplicationStatus.CREATED]: [
    ApplicationStatus.REJECTED,
    ApplicationStatus.WAITLISTED,
    ApplicationStatus.ACCEPTED,
    ApplicationStatus.CANCELED,
    ApplicationStatus.APPLIED,
  ],
  [ApplicationStatus.VERIFIED]: [
    ApplicationStatus.REJECTED,
    ApplicationStatus.WAITLISTED,
    ApplicationStatus.ACCEPTED,
    ApplicationStatus.CANCELED,
    ApplicationStatus.APPLIED,
  ],
  [ApplicationStatus.APPLIED]: [
    ApplicationStatus.REJECTED,
    ApplicationStatus.WAITLISTED,
    ApplicationStatus.ACCEPTED,
    ApplicationStatus.CANCELED,
  ],
  [ApplicationStatus.WAITLISTED]: [
    ApplicationStatus.REJECTED,
    ApplicationStatus.ACCEPTED,
    ApplicationStatus.APPLIED,
    ApplicationStatus.CANCELED,
  ],
  [ApplicationStatus.ACCEPTED]: [
    ApplicationStatus.REJECTED,
    ApplicationStatus.CONFIRMED,
    ApplicationStatus.CANCELED,
    ApplicationStatus.APPLIED,
  ],
  [ApplicationStatus.CONFIRMED]: [
    ApplicationStatus.REJECTED,
    ApplicationStatus.DEPOSITED,
    ApplicationStatus.APPLIED,
    ApplicationStatus.CANCELED,
  ],
  [ApplicationStatus.DEPOSITED]: [
    ApplicationStatus.REJECTED,
    ApplicationStatus.VOTING,
    ApplicationStatus.APPLIED,
  ],
  [ApplicationStatus.VOTING]: [
    ApplicationStatus.REJECTED,
    ApplicationStatus.VOTED,
    ApplicationStatus.APPLIED,
    ApplicationStatus.CANCELED,
  ],
  [ApplicationStatus.VOTED]: [
    ApplicationStatus.REJECTED,
    ApplicationStatus.GAME_ASSIGNED,
    ApplicationStatus.APPLIED,
    ApplicationStatus.CANCELED,
  ],
  [ApplicationStatus.GAME_ASSIGNED]: [
    ApplicationStatus.REJECTED,
    ApplicationStatus.ARRIVED,
    ApplicationStatus.NO_ARRIVAL,
    ApplicationStatus.APPLIED,
    ApplicationStatus.CANCELED,
  ],
  [ApplicationStatus.ARRIVED]: [
    ApplicationStatus.FEEDBACK_REQUESTED,
    ApplicationStatus.APPLIED,
  ],
  [ApplicationStatus.NO_ARRIVAL]: [],
  [ApplicationStatus.FEEDBACK_REQUESTED]: [
    ApplicationStatus.FEEDBACK_RECEIVED,
    ApplicationStatus.APPLIED,
  ],
  [ApplicationStatus.FEEDBACK_RECEIVED]: [
    ApplicationStatus.DEPOSIT_DONATED,
    ApplicationStatus.DEPOSIT_RETURNED,
    ApplicationStatus.APPLIED,
  ],
  [ApplicationStatus.DEPOSIT_DONATED]: [ApplicationStatus.APPLIED],
  [ApplicationStatus.DEPOSIT_RETURNED]: [ApplicationStatus.APPLIED],
  [ApplicationStatus.CANCELED]: [ApplicationStatus.APPLIED],
  [ApplicationStatus.REJECTED]: [ApplicationStatus.APPLIED],
};

// Check if a status change is allowed
export function canTransitionStatus(
  fromStatus: ApplicationStatus,
  toStatus: ApplicationStatus
): boolean {
  const allowedStatuses = StatusFlow[fromStatus];
  return allowedStatuses?.includes(toStatus);
}

// Example usage
const fromStatus = ApplicationStatus.APPLIED;
const toStatus = ApplicationStatus.ACCEPTED;
console.log(
  `Can transition from ${fromStatus} to ${toStatus}:`,
  canTransitionStatus(fromStatus, toStatus)
);
