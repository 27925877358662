import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Layout } from "./layout/Layout";
import { HomePage } from "./pages/HomePage";
import { NoMatchPage } from "./pages/NoMatchPage";
import CssBaseline from "@mui/material/CssBaseline";
import { ApplicationsPage } from "./pages/applications/ApplicationsPage";
import { ThemeProvider } from "@emotion/react";
import { useMediaQuery, createTheme, Typography } from "@mui/material";
import { LoginPage } from "./pages/auth/LoginPage";
import { RequireAuth } from "./guards/RequireAuth";
import { RequireNoAuth } from "./guards/RequireNoAuth";
import { LoginVerifyPage } from "./pages/auth/LoginVerifyPage";
import { LogoutPage } from "./pages/auth/LogoutPage";
import { GamesPage } from "./pages/games/GamesPage";
import { UserEmailsPage } from "./pages/UserEmailsPage";
import { UsersPage } from "./pages/users/UsersPage";
import { FeedbacksPage } from "./pages/feedbacks/FeedbacksPage";
import { DepositsTable } from "./pages/deposits/DepositsTable";
import { MeetupsPage } from "./pages/meetups/MeetupsPage";
import { ApplicationDetailsPage } from "./pages/applications/ApplicationDetailsPage";
import { UserDetailsPage } from "./pages/users/UserDetailsPage";
import { OldMeetupsPage } from "./pages/meetups/old/MeetupsPage";
import { MeetupDetailsPage } from "./pages/meetups/old/MeetupDetailsPage";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: "light",
          // mode: prefersDarkMode ? "dark" : "light",
        },
        typography: {
          h1: {
            fontSize: "2.5rem", // Larger headings
          },
          h2: {
            fontSize: "2rem",
          },
          h3: {
            fontSize: "1.75rem",
          },
          h4: {
            fontSize: "1.5rem",
          },
          h5: {
            fontSize: "1.25rem",
          },
          h6: {
            fontSize: "1rem", // Smallest headings
          },
          body1: {
            fontSize: "1rem", // Default body text
          },
          body2: {
            fontSize: "0.875rem", // Slightly smaller body text
          },
          caption: {
            fontSize: "0.75rem", // Smallest text (captions)
          },
        },
      }),
    [prefersDarkMode]
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route element={<RequireAuth />}>
              <Route index element={<HomePage />} />
              <Route path="games" element={<GamesPage />} />
              <Route path="meetups" element={<MeetupsPage />} />
              <Route path="meetupsOld" element={<OldMeetupsPage />} />
              <Route path="meetups/:id" element={<MeetupDetailsPage />} />

              <Route path="users" element={<UsersPage />} />
              <Route path="users/:id" element={<UserDetailsPage />} />

              <Route path="applications" element={<ApplicationsPage />} />
              <Route
                path="applications/:id"
                element={<ApplicationDetailsPage />}
              />

              <Route path="userEmails" element={<UserEmailsPage />} />
              <Route path="feedbacks" element={<FeedbacksPage />} />
              <Route path="deposits" element={<DepositsTable />} />
            </Route>
          </Route>
          <Route element={<RequireNoAuth />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="login/verify" element={<LoginVerifyPage />} />
          </Route>
          <Route path="logout" element={<LogoutPage />} />
          <Route path="*" element={<NoMatchPage />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
