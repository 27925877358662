import React, { ReactNode } from "react";
import { useForm, Controller, ControllerRenderProps } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DATETIME_FORMAT } from "../../helpers/formatHelper";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  TextField,
} from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import { TextFieldArray } from "./fields/TextFieldArray";
import { DeepPartial } from "@reduxjs/toolkit";
import { grey } from "@mui/material/colors";
import { MaterialColor } from "src/types/MaterialColor";

interface FormFields {
  [key: string]: any;
}

interface Props<T extends yup.AnyObject> {
  onSubmit: (data: FormFields) => void;
  validationSchema: yup.ObjectSchema<T>;
  defaultValues?: DeepPartial<T>;
  submitText?: string;
  submitColor?: MaterialColor;
}
/** @deprecated */
export const DeprecatedEntityForm = <T extends yup.AnyObject>({
  onSubmit,
  validationSchema,
  defaultValues,
  submitText,
  submitColor,
}: Props<T>) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormFields>({
    defaultValues,
    resolver: yupResolver(validationSchema as yup.ObjectSchema<any>),
  });

  const onSubmitHandler = (data: FormFields) => {
    debugger;
    onSubmit(data);
  };
  const all = watch();

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      {Object.entries<any>(validationSchema.fields).map(
        ([fieldName, fieldDef]) => (
          <Box sx={{ my: 2 }} key={fieldName}>
            <Controller
              key={fieldName}
              name={fieldName}
              control={control}
              // defaultValue=""
              render={({ field }) =>
                fieldDef.type === "date" ? (
                  <DateTimePicker
                    renderInput={(props) => {
                      return (
                        <>
                          {JSON.stringify(props.value)}
                          <TextField
                            variant="outlined"
                            // required
                            {...(props as any)}
                            error={!!errors[fieldName]}
                            fullWidth
                            helperText={
                              <ErrorMessage errors={errors} name={fieldName} />
                            }
                          />
                        </>
                      );
                    }}
                    label={fieldName}
                    disableMaskedInput
                    inputFormat={DATETIME_FORMAT}
                    {...field}
                  />
                ) : fieldDef?.spec?.meta?.type ==
                  DeprecatedEntityFieldType.TEXT_ARRAY ? (
                  <TextFieldArray
                    control={control}
                    name={fieldName}
                    errors={errors}
                  />
                ) : fieldDef?.spec?.meta?.type ==
                  DeprecatedEntityFieldType.CHECKBOX ? (
                  <Controller
                    key={fieldName}
                    name={fieldName}
                    control={control}
                    // defaultValue=""
                    render={({ field }) => (
                      <FormControlLabel
                        label={fieldName}
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                          />
                        }
                      />
                    )}
                  />
                ) : fieldDef?.spec?.meta?.type ==
                  DeprecatedEntityFieldType.AUTOCOMPLETE ? (
                  <>
                    <FormControl fullWidth error={!!errors[fieldName]}>
                      <Autocomplete
                        {...field}
                        onChange={(e, value) => {
                          field.onChange(value?.value);
                        }}
                        value={(
                          fieldDef.spec.meta
                            .options as DeprecatedEntityFieldSelectOption[]
                        ).find((option) => option.value === field.value)}
                        options={fieldDef.spec.meta.options}
                        getOptionLabel={(option) =>
                          option.label || option.value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={fieldName}
                            variant="outlined"
                            fullWidth
                            error={!!errors[fieldName]}
                            helperText={
                              <ErrorMessage errors={errors} name={fieldName} />
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </>
                ) : (
                  <TextField
                    label={fieldName}
                    variant="outlined"
                    fullWidth
                    error={!!errors[fieldName]}
                    helperText={
                      <ErrorMessage errors={errors} name={fieldName} />
                    }
                    {...field}
                  />
                )
              }
            />
          </Box>
        )
      )}
      <Button variant="contained" type="submit" color={submitColor}>
        {submitText || "Submit"}
      </Button>
      <Box sx={{ fontSize: "12px" }}>
        <Paper
          variant="outlined"
          sx={{ mt: 4, py: 0.5, px: 2, background: grey[100] }}
        >
          <pre>{JSON.stringify(all, null, "  ")}</pre>
        </Paper>
      </Box>
    </form>
  );
};

/** @deprecated */
export enum DeprecatedEntityFieldType {
  SELECT = "SELECT",
  AUTOCOMPLETE = "AUTOCOMPLETE",
  TEXT_ARRAY = "TEXT_ARRAY",
  CHECKBOX = "CHECKBOX",
}
/** @deprecated */
export type DeprecatedEntityFieldSelectOption<T = string> = {
  label?: string;
  value: T;
};

/** @deprecated */
export function deprecatedCreateEntityFieldMeta(
  type: DeprecatedEntityFieldType,
  config?: {
    options?: DeprecatedEntityFieldSelectOption[];
    // transform?: (e: any) => any;
  }
) {
  let res: any = { type };
  if (config?.options) {
    res = { ...res, options: config.options };
  }
  return res;
}
