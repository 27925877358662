import { Alert, Button, Typography } from "@mui/material";
import { AppBaseDialog } from "../AppBaseDialog";
import { DeprecatedEntityForm } from "../../EntityTable/DeprecatedEntityForm";

import * as yup from "yup";
import axios, { Method } from "axios";
import { useEffect, useState } from "react";
import { useNotification } from "src/hooks/useNotification";
import { DeepPartial, FieldValues } from "react-hook-form";
import { MaterialColor } from "src/types/MaterialColor";
import { EntityDialogFields, EntityDialogForm } from "./EntityDialogForm";

export type BaseEntityDialogProps<T extends FieldValues> = {
  submitUrl: string;
  submitMethod: Method;
  entityName?: string;
  title?: string;
  description?: string;
  onSuccess: (item: any) => void;
  fields?: EntityDialogFields;
  initialValues?: DeepPartial<T>;
  action: string;
  open: boolean;
  onOpenChange: (e: boolean) => void;
  submitText?: string;
  submitColor?: MaterialColor;
};

export const BaseEntityDialog = <T extends FieldValues>({
  entityName,
  // validationSchema,
  initialValues,
  onSuccess,
  submitUrl,
  submitMethod,
  action,
  title,
  description,
  open: openFromParent,
  onOpenChange,
  submitText,
  submitColor,
  fields,
}: BaseEntityDialogProps<T>) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openFromParent);
  }, [openFromParent]);

  useEffect(() => {
    onOpenChange(open);
  }, [open]);

  const { showNotification } = useNotification();
  const [error, setError] = useState<string | false>(false);

  const onSubmit = async (data: any) => {
    try {
      setError(false);
      const res = await axios<{ message: string; item: any }>({
        method: submitMethod,
        url: submitUrl,
        data,
      });
      showNotification({
        severity: "success",
        message: res.data?.message || "Success",
      });
      onSuccess(res.data?.item);
      setOpen(false);
    } catch (e: any) {
      const message =
        e?.response?.data?.error || e?.response?.data?.message || "Error";
      setError(message);
      showNotification({
        severity: "error",
        message,
      });
    }
  };

  return (
    <AppBaseDialog
      open={open}
      title={!!title ? title : `${action} ${entityName}`}
      content={
        <>
          {description && <Typography>{description}</Typography>}
          {/* {validationSchema ? ( */}
          {fields ? (
            <EntityDialogForm
              onSubmit={onSubmit}
              fields={fields}
              // validationSchema={validationSchema}
              // defaultValues={defaultValues}
              initialValues={initialValues}
              submitText={submitText}
              submitColor={submitColor}
            />
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => onSubmit({})}
                color={submitColor}
              >
                {submitText || "Submit"}
              </Button>
            </>
          )}

          {error && (
            <Alert severity="error" sx={{ mt: 4 }}>
              {error}
            </Alert>
          )}
        </>
      }
      onClose={() => setOpen(false)}
    />
  );
};
