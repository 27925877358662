import { Add, Edit } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, Button, Box } from "@mui/material";
import { MeetupModel } from "@pastukh-dm/wboard-games";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UpdateEntityDialog } from "src/components/dialogs/EntityDialog/UpdateEntityDialog";
import { useMeetupFields } from "../hooks/useMeetupFields";

export const EditMeetupButton = ({ item }: { item: MeetupModel }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { fields } = useMeetupFields();

  // name: yup.string().required(),
  // address: yup.string().required(),
  // level: yup
  //   .mixed()
  //   .oneOf(Object.values(MeetupLevel))
  //   .required()
  //   .meta(
  //     createEntityFieldMeta(EntityFieldType.AUTOCOMPLETE, {
  //       options: Object.values(MeetupLevel).map((value) => ({ value })),
  //     })
  //   ),
  // startAt: yup.date().required(),
  // notified: yup
  //   .boolean()
  //   .required()
  //   .meta(createEntityFieldMeta(EntityFieldType.CHECKBOX)),
  // applyFrom: yup.date().required(),
  // applyTo: yup.date().required(),
  // chatLink: yup.string().optional().nullable(),
  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <UpdateEntityDialog
        updateUrl={`/meetups/${item.id}`}
        entityName={"Meetup"}
        onSuccess={(item) => {
          navigate(0);
        }}
        // validationSchema={validationSchema}
        fields={fields}
        open={open}
        onOpenChange={setOpen}
        initialValues={{
          name: item.name,
          level: item.level,
          address: item.address,
          startAt: dayjs(item.startAt).toDate(),
          notified: item.notified,
          applyFrom: dayjs(item.applyFrom).toDate(),
          applyTo: dayjs(item.applyTo).toDate(),
          chatLink: item.chatLink,
        }}
      />
      <Button
        startIcon={<Edit />}
        onClick={() => setOpen(true)}
        variant="outlined"
        color="warning"
      >
        Edit Meetup
      </Button>
    </Box>
  );
};
