import { IconButton } from "@mui/material";
import axios from "axios";
import { useMemo, useState } from "react";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import { useNotification } from "../../hooks/useNotification";
import { AppBaseDialog } from "../dialogs/AppBaseDialog";
import {
  DeprecatedEntityFieldSelectOption,
  DeprecatedEntityForm,
} from "./DeprecatedEntityForm";

/** @deprecated */
type Props = {
  updateUrl: string;
  entityName: string;
  onSuccess: () => void;
  validationSchema: yup.AnyObjectSchema;
  item: any;
};

/** @deprecated */
export const DeprecatedUpdateEntity = ({
  updateUrl,
  entityName,
  onSuccess,
  validationSchema,
  item,
}: Props) => {
  const [open, setOpen] = useState(false);

  function handleButtonClick() {
    setOpen(true);
  }

  const { showNotification } = useNotification();
  const onSubmit = async (data: any) => {
    try {
      const res = await axios.patch<{ message: string }>(
        item?.id ? updateUrl.replace(":id", item.id) : updateUrl,
        data
      );
      showNotification({
        severity: "success",
        message: res.data.message,
      });
      onSuccess();
      setOpen(false);
    } catch (e: any) {
      showNotification({
        severity: "error",
        message: e?.response?.data?.error,
      });
    }
  };
  const defaultValues = useMemo(() => {
    const fieldKeys = Object.keys(validationSchema.fields);
    const vals = Object.fromEntries(
      Object.entries(item).filter(([key, value]) => fieldKeys.includes(key))
    );
    return vals;
  }, [item, validationSchema]);

  return (
    <>
      <IconButton onClick={handleButtonClick} color="secondary">
        <EditIcon />
      </IconButton>
      <AppBaseDialog
        open={open}
        title={`Update ${entityName}`}
        content={
          defaultValues ? (
            <DeprecatedEntityForm
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              defaultValues={defaultValues}
            />
          ) : (
            <>Loading...</>
          )
        }
      />
    </>
  );
};
