import { Navigate, Outlet } from "react-router-dom";

export const RequireAuth = () => {
  const isAuthenticated = !!localStorage.getItem("authToken");

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" /> // Redirect to the login page if not authenticated
  );
};
