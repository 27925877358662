import { Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { NoMatchPage } from "../NoMatchPage";

export const LoginVerifyPage = () => {
  // const dispatch = useAppDispatch();
  const [search] = useSearchParams();
  const token = search.get("token");

  const navigate = useNavigate();

  useEffect(() => {
    if (!!token) {
      axios
        .post("/auth/verify", { token }, { withCredentials: true })
        .then((res) => {
          localStorage.setItem("authToken", token);
          navigate("/");
          // console.log(res.data);
          // dispatch(authActions.setTokenData(res.data));
          // window.location.href = ROUTES.Account;
        });
    }
  }, []);

  if (!token) {
    return <NoMatchPage />;
  }
  return (
    <>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Verifying...
        </Typography>
      </Container>
    </>
  );
};
