import React, { useState } from "react";
import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Divider,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { AlertColor } from "@mui/material";
import axios from "axios";
import { BackupButton, useOnBackup } from "./BackupButton";
import {
  LayoutNotification,
  LayoutNotificationContext,
} from "./LayoutNotification";

const leftPages = [
  { to: "/meetups", text: "Meetups" },
  { to: "/applications", text: "Applications" },
  { to: "/users", text: "Users" },
  { to: "/games", text: "Games" },
];

const rightPages = [{ to: "/logout", text: "Log out" }];

export const Layout = () => {
  const [notification, setNotification] = useState("");
  const [severity, setSeverity] = useState<AlertColor | undefined>();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const { onBackup } = useOnBackup();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const onMigrateScript = async () => {
    const res = await axios.post("/migrate-script");
  };

  return (
    <LayoutNotificationContext.Provider
      value={{
        notification,
        severity,
        showNotification: (e) => {
          setNotification(e.message);
          setSeverity(e.severity);
        },
      }}
    >
      <AppBar position="static">
        <Container>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box sx={{ flexGrow: 1, display: { md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="open mobile menu"
                edge="start"
                onClick={toggleMobileMenu}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              {leftPages.map(({ to, text }) => (
                <Button
                  component={RouterLink}
                  to={to}
                  key={text}
                  sx={{
                    color: "#fff",
                    marginRight: 2,
                    background:
                      to === location.pathname ? "#fff3" : "transparent",
                    "&:hover": {
                      background:
                        to === location.pathname ? "#fff3" : "transparent",
                    },
                  }}
                >
                  {text}
                </Button>
              ))}{" "}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={onMigrateScript}
                sx={{ color: "primary.light" }}
                variant="text"
              >
                Migrate script
              </Button>
              <Button
                onClick={onBackup}
                sx={{ color: "warning.light" }}
                variant="text"
              >
                Backup
              </Button>
              {rightPages.map(({ to, text }) => (
                <Button
                  component={RouterLink}
                  to={to}
                  key={text}
                  sx={{ color: "#fff" }}
                >
                  {text}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl">
        <Box sx={{ mt: 4, mb: 8, p: 0 }}>
          <Outlet />
        </Box>
      </Container>
      <LayoutNotification />
      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={closeMobileMenu}
        sx={{
          display: { md: "none" },
          "& .MuiDrawer-paper": { minWidth: "200px" },
        }}
      >
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {leftPages.map(({ to, text }) => (
            <ListItem
              button
              key={text}
              component={RouterLink}
              to={to}
              onClick={closeMobileMenu}
              sx={{
                background: to === location.pathname ? "#fff3" : "transparent",
              }}
            >
              <ListItemText
                primary={text}
                primaryTypographyProps={{
                  color: to === location.pathname ? "primary" : "inherit",
                }}
              />
            </ListItem>
          ))}
          <Box sx={{ flex: "100%" }} />
          <Divider />
          {rightPages.map(({ to, text }) => (
            <ListItem
              button
              key={text}
              component={RouterLink}
              to={to}
              onClick={closeMobileMenu}
              sx={{ color: "error.main" }}
            >
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </LayoutNotificationContext.Provider>
  );
};
