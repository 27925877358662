import { Payment, People, PeopleOutline } from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  MeetupWithRelations,
  ApplicationWith,
  DepositStatus,
  ApplicationStatus,
  FeedbackDepositAction,
} from "@pastukh-dm/wboard-games";
import dayjs from "dayjs";
import { useMemo } from "react";
import { formatDatetime } from "src/helpers/formatHelper";
import { CountWithIcon } from "./CountWithIcon";

export const MeetupCard = ({ data }: { data: MeetupWithRelations }) => {
  const theme = useTheme();
  const unresolvedDepositCount = useMemo(
    () =>
      (data?.applications as ApplicationWith<"deposit" | "feedback">[])?.filter(
        (item) => {
          if (item.deposit?.status === DepositStatus.NOT_NEEDED) {
            return false;
          }
          const notDonatedButShould =
            item.feedback?.depositAction === FeedbackDepositAction.DONATE &&
            item.deposit?.status !== DepositStatus.DONATED;
          const notReturnedButShould =
            item.feedback?.depositAction === FeedbackDepositAction.RETURN &&
            item.deposit?.status !== DepositStatus.RETURNED;
          return notDonatedButShould || notReturnedButShould;
        }
      ).length,
    [data?.applications]
  );
  if (!data) {
    return null;
  }

  const hasEnded = dayjs(data.startAt).isBefore(dayjs());
  const isSoon =
    dayjs(data.startAt).isBefore(dayjs().add(1, "week")) &&
    dayjs(data.startAt).isAfter(dayjs());
  const wasRecently =
    dayjs(data.startAt).isAfter(dayjs().subtract(7, "day")) &&
    dayjs(data.startAt).isBefore(dayjs());

  const hasUnresolvedDeposits = unresolvedDepositCount > 0;

  return (
    <Card
      variant="outlined"
      sx={{
        height: "100%",
        background: hasEnded && !wasRecently ? grey[50] : "#fff",
        borderColor: hasEnded && !wasRecently ? grey[50] : undefined,
      }}
    >
      <CardActionArea
        href={`meetups/${data.id}`}
        sx={{
          height: "100%",
          alignItems: "flex-start",
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
          // opacity: hasEnded && !wasRecently ? 0.65 : 1
        }}
      >
        <CardContent>
          <Typography variant="h6" component="div">
            {data.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatDatetime(data.startAt)}
          </Typography>

          {isSoon ? (
            <Typography
              variant="body2"
              fontWeight="bold"
              mt={2}
              sx={{ color: theme.palette.success.main }}
            >
              This week
            </Typography>
          ) : null}

          {hasEnded ? (
            <Typography
              variant="body2"
              fontWeight="bold"
              mt={2}
              sx={{ color: theme.palette.grey[500] }}
            >
              Ended
            </Typography>
          ) : null}

          {wasRecently ? (
            <Typography
              variant="body2"
              fontWeight="bold"
              mt={2}
              sx={{ color: theme.palette.primary.main }}
            >
              Ended last week
            </Typography>
          ) : null}

          {hasEnded && hasUnresolvedDeposits ? (
            <Typography
              variant="body2"
              fontWeight="bold"
              mt={1}
              sx={{ color: theme.palette.warning.main }}
            >
              Has unresolved deposits ({unresolvedDepositCount})
            </Typography>
          ) : null}
        </CardContent>
        <CardContent sx={{ display: "flex", gap: 2 }}>
          <CountWithIcon
            count={unresolvedDepositCount}
            icon={Payment}
            color={unresolvedDepositCount ? "warning" : undefined}
            tooltip="Unresolved deposits"
          />
          <CountWithIcon
            count={
              data.applications?.filter(
                (item) =>
                  item.status !== ApplicationStatus.CANCELED &&
                  item.status !== ApplicationStatus.NO_ARRIVAL
              ).length
            }
            icon={People}
            tooltip="Paricipants"
          />
          <CountWithIcon
            count={data.applications?.length}
            icon={PeopleOutline}
            tooltip="Applications"
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
