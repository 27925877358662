import {
  Box,
  Chip,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import React, { ReactElement, ReactNode, useMemo, useState } from "react";

export interface ChipWithMenuProps {
  label: string;
  menuItems: Array<string>;
  onClickMenuItem: (item: string) => void;
  menuType?: "click" | "context" | "both";
  icon?: ReactElement;
  color?: string;
  bgColor?: string;
  disabled?: boolean;
}

export const ChipWithMenu: React.FC<ChipWithMenuProps> = ({
  label,
  menuItems,
  onClickMenuItem,
  menuType = "click",
  icon,
  color,
  bgColor,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (menuType === "click" || menuType === "both") {
      setIsOpen((prev) => !prev);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleContextClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (menuType === "context" || menuType === "both") {
      setIsOpen((prev) => !prev);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e: React.MouseEvent, item: string) => {
    e.preventDefault();
    e.stopPropagation();
    onClickMenuItem(item);
    handleClose();
  };

  const visibleMenuItems = useMemo(
    () => menuItems.filter((item) => item !== label),
    [menuItems, label]
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
      <Chip
        label={label}
        onClick={handleClick}
        onContextMenu={handleContextClick}
        size="small"
        icon={icon}
        disabled={disabled}
        sx={{ background: bgColor, color, ".MuiChip-icon": { color } }}
      />
      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        transition
        disablePortal
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {visibleMenuItems.map((item) => (
                    <MenuItem
                      dense
                      key={item}
                      onClick={(e) => handleMenuItemClick(e, item)}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
