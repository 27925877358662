import { Add } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, Button } from "@mui/material";
import { MeetupLevel } from "@pastukh-dm/wboard-games";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateEntityDialog } from "src/components/dialogs/EntityDialog/CreateEntityDialog";
import { FieldType } from "src/components/dialogs/EntityDialog/EntityDialogForm";
import * as z from "zod";
import { useMeetupFields } from "../hooks/useMeetupFields";

export const AddMeetupCard = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { fields } = useMeetupFields();

  return (
    <>
      <CreateEntityDialog
        createUrl={"/meetups"}
        entityName={"Meetup"}
        onSuccess={() => navigate(0)}
        fields={fields}
        open={open}
        onOpenChange={setOpen}
      />
      <Card variant="outlined" sx={{ height: "100%" }}>
        <CardActionArea
          sx={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
          onClick={() => setOpen(true)}
        >
          <CardContent>
            <Button startIcon={<Add />}>Add Meetup</Button>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};
