import { Container, Typography } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const LogoutPage = () => {
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken === null) {
      return navigate("/");
    }
    axios.get("/auth/logout", { withCredentials: true }).finally(() => {
      // dispatch(authActions.setTokenData(null));
      localStorage.removeItem("authToken");
      // window.location.href = ROUTES.AuthLogin;
      navigate("/");
    });
  }, []);

  return (
    <>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Logging out...
        </Typography>
      </Container>
    </>
  );
};
