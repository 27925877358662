import * as yup from "yup";
import { BaseEntityDialog, BaseEntityDialogProps } from "./BaseEntityDialog";

type Props<T extends yup.AnyObject> = Omit<
  BaseEntityDialogProps<T>,
  "submitUrl" | "submitMethod" | "action"
> & { updateUrl: BaseEntityDialogProps<T>["submitUrl"] };

export const UpdateEntityDialog = <T extends yup.AnyObject>({
  entityName,
  // validationSchema,
  fields,
  initialValues: defaultValues,
  onSuccess,
  updateUrl,
  open,
  onOpenChange,
}: Props<T>) => {
  return (
    <BaseEntityDialog
      action="Update"
      submitMethod={"PATCH"}
      submitUrl={updateUrl}
      entityName={entityName}
      // validationSchema={validationSchema}
      fields={fields}
      initialValues={defaultValues}
      onSuccess={onSuccess}
      open={open}
      onOpenChange={onOpenChange}
    />
  );
};
