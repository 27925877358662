import { useState, useEffect } from "react";
import { ListItem, Typography, Box, Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import axios from "axios";
import { GameModel } from "@pastukh-dm/wboard-games";
import { MeetupGame } from "src/hooks/fetchers/useMeetupGamesFetcher";
import { ChipWithMenu } from "src/components/ChipWithMenu";

export const ApplicationGameItem = ({
  game,
  meetupGames,
  meetupId,
  userId,
}: {
  game: GameModel;
  meetupGames: MeetupGame[];
  meetupId: string;
  userId: string;
}) => {
  const addedGame = meetupGames?.find(
    (item) => item.game.geekId === game.geekId
  );

  const [selectedLabel, setSelectedLabel] = useState<string>("Not added");

  useEffect(() => {
    if (addedGame) {
      setSelectedLabel(addedGame.isMainGame ? "Main" : "Cooldown");
    } else {
      setSelectedLabel("Not added");
    }
  }, [addedGame]);

  const handleLabelSelect = async (label: string) => {
    setSelectedLabel(label);

    try {
      if (label === "Not added") {
        await axios.delete(`/meetups/${meetupId}/games/${game.id}`);
        console.log(`Game ${game.name} removed from meetup.`);
      } else {
        const isMainGame = label === "Main";
        await axios.post(`/meetups/${meetupId}/games`, {
          userId,
          isMainGame,
          geekIds: [game.geekId],
        });
        console.log(
          `Game ${game.name} added as ${isMainGame ? "Main" : "Cooldown"}.`
        );
      }
    } catch (error) {
      console.error("Error updating game:", error);
    }
  };

  const menuItems = ["Main", "Cooldown", "Not added"];

  const labelColors: Record<string, { bgColor: string; color: string }> = {
    Main: { bgColor: "#F0FFF0", color: "#77DD77" },
    Cooldown: { bgColor: "#F0F8FF", color: "#89CFF0" },
    "Not added": { bgColor: "red", color: "white" },
  };

  const { bgColor, color } = labelColors[selectedLabel];

  const truncateGameName = (name: string, maxLength: number) => {
    return name.length > maxLength
      ? `${name.substring(0, maxLength)}...`
      : name;
  };

  const gameName = game.name ?? "Unknown Game";
  const isTruncated = gameName.length > 20;

  return (
    <ListItem sx={{ padding: 0 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          whiteSpace: "nowrap",
        }}
      >
        <Tooltip title={isTruncated ? gameName : ""} arrow>
          <Typography
            sx={{
              fontSize: "0.875rem",
              color: addedGame ? "gray" : "inherit",
              textTransform: "none",
            }}
          >
            <a
              href={`https://boardgamegeek.com/boardgame/${game.geekId}`}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              <LinkIcon fontSize="small" />
            </a>{" "}
            {truncateGameName(gameName, 20)}
          </Typography>
        </Tooltip>
        <ChipWithMenu
          label={selectedLabel}
          menuItems={menuItems}
          onClickMenuItem={handleLabelSelect}
          color={color}
          bgColor={bgColor}
        />
      </Box>
    </ListItem>
  );
};
