import { Delete } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteEntityDialog } from "src/components/dialogs/EntityDialog/DeleteEntityDialog";

export const DeleteUserGameButton = ({
  userId,
  gameId,
  gameName,
}: {
  userId: string;
  gameId: string;
  gameName: string;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <DeleteEntityDialog
        deleteUrl={`/users/${userId}/games/${gameId}`}
        title={`Delete "${gameName}" from this user?`}
        onSuccess={() => {
          navigate(0);
        }}
        open={open}
        onOpenChange={setOpen}
        initialValues={{}}
      />
      <IconButton
        onClick={(e) => {
          setOpen(true);
        }}
        color="error"
      >
        <Delete />
      </IconButton>
    </Box>
  );
};
