import { Box, Typography, List } from "@mui/material";
import { ApplicationGameItem } from "./ApplicationGameItem";
import { GameModel } from "@pastukh-dm/wboard-games";
import { MeetupGame } from "src/hooks/fetchers/useMeetupGamesFetcher";

export const ApplicationGameList = ({
  title,
  games,
  meetupGames,
  meetupId,
  userId,
}: {
  title: string;
  games: GameModel[];
  meetupGames: MeetupGame[];
  meetupId: string;
  userId: string;
}) => {
  if (!games || games.length === 0) return null;

  return (
    <Box>
      <Typography variant="subtitle2" component="b">
        {title}:
      </Typography>
      <List>
        {games.map((game) => (
          <ApplicationGameItem
            key={game.geekId}
            game={game}
            meetupGames={meetupGames}
            meetupId={meetupId}
            userId={userId}
          />
        ))}
      </List>
    </Box>
  );
};
