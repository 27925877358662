import { MaterialColor } from "src/types/MaterialColor";
import * as yup from "yup";
import { BaseEntityDialog, BaseEntityDialogProps } from "./BaseEntityDialog";

type Props<T extends yup.AnyObject> = Omit<
  BaseEntityDialogProps<T>,
  "submitUrl" | "submitMethod" | "action"
> & { createUrl: BaseEntityDialogProps<T>["submitUrl"] };

export const CreateEntityDialog = <T extends yup.AnyObject>({
  entityName,
  // validationSchema,
  fields,
  initialValues: defaultValues,
  onSuccess,
  createUrl,
  open,
  onOpenChange,
}: Props<T>) => {
  return (
    <BaseEntityDialog
      action="Create"
      submitMethod={"POST"}
      submitUrl={createUrl}
      entityName={entityName}
      fields={fields}
      // validationSchema={validationSchema}
      initialValues={defaultValues}
      onSuccess={onSuccess}
      open={open}
      onOpenChange={onOpenChange}
      submitText={"Create"}
      submitColor={MaterialColor.PRIMARY}
    />
  );
};
