import axios from "axios";
import { useNotification } from "../../hooks/useNotification";
import { AppBaseDialog } from "../dialogs/AppBaseDialog";
import { useState } from "react";
import { Alert, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DeprecatedEntityForm } from "./DeprecatedEntityForm";

import * as yup from "yup";

type Props = {
  createUrl: string;
  entityName: string;
  onSuccess: () => void;
  validationSchema: yup.AnyObjectSchema;
};

/** @deprecated */
export const DeprecatedCreateEntity = ({
  createUrl,
  entityName,
  onSuccess,
  validationSchema,
}: Props) => {
  const [open, setOpen] = useState(false);

  function handleButtonClick() {
    setOpen(true);
  }

  const { showNotification } = useNotification();
  const [error, setError] = useState<string | false>(false);
  const onSubmit = async (data: any) => {
    try {
      setError(false);
      const res = await axios.post<{ message: string }>(createUrl, data);
      showNotification({
        severity: "success",
        message: res.data.message,
      });
      onSuccess();
      setOpen(false);
    } catch (e: any) {
      const message =
        e?.response?.data?.error || e?.response?.data?.message || "Error";
      setError(message);
      showNotification({
        severity: "error",
        message,
      });
    }
  };

  return (
    <>
      <IconButton onClick={handleButtonClick}>
        <AddIcon />
      </IconButton>
      <AppBaseDialog
        open={open}
        title={`Create ${entityName}`}
        content={
          <>
            <DeprecatedEntityForm
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            />

            {error && (
              <Alert severity="error" sx={{ mt: 4 }}>
                {error}
              </Alert>
            )}
          </>
        }
        onClose={() => setOpen(false)}
      />
    </>
  );
};
