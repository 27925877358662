import { GamesOnUsersWith } from "@pastukh-dm/wboard-games";
import { useParams, useNavigate } from "react-router-dom";
import { FetchRender } from "src/components/FetchRender";
import { ListTable } from "src/components/ListTable";
import { useFetch } from "src/hooks/useFetch";
import { useRequest } from "src/hooks/useRequest";
import { AddUserGameButton } from "./AddUserGameButton";
import { DeleteUserGameButton } from "./DeleteUserGameButton";

export const UserGamesTab = () => {
  const { id } = useParams();
  const {
    data: games,
    error,
    refetch,
    isLoading,
  } = useFetch<GamesOnUsersWith<"game">[]>({
    url: `/users/${id}/games`,
  });

  const { request } = useRequest();
  const navigate = useNavigate();

  return (
    <FetchRender data={games} error={error} isLoading={isLoading}>
      {games && (
        <>
          <AddUserGameButton userGames={games} />
          <ListTable
            // onRowClick={(item) => navigate(`/games/${item.game.id}`)}
            columns={[
              {
                label: "Game",
                render: (val) => val.game.name,
              },
              {
                label: "Weight",
                render: (val) => val.game.weight,
              },
              {
                label: "Max. players",
                render: (val) => val.game.maxPlayers,
              },
              {
                label: "Max. playtime",
                render: (val) => val.game.maxPlaytime,
              },
              {
                label: "Max. playtime",
                render: (val) => val.game.maxPlaytime,
              },
              {
                label: "Actions",
                render: (val) => (
                  <>
                    <DeleteUserGameButton
                      userId={val.userId}
                      gameId={val.gameId}
                      gameName={val.game.name as string}
                    />
                  </>
                ),
              },
            ]}
            data={games.sort((a, b) =>
              !!a.game?.weight &&
              !!b.game?.weight &&
              a.game.weight < b.game.weight
                ? 1
                : -1
            )}
          />
        </>
      )}
    </FetchRender>
  );
};
