import { FileCopyOutlined } from "@mui/icons-material";
import {
  Stack,
  Box,
  Tooltip,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState } from "react";

function getTruncatedContent(
  content: string | null | undefined,
  maxLength: number,
  truncatePosition: "start" | "middle" | "end"
): string {
  if (!content) return "";

  const contentLength = content.length;

  if (contentLength <= maxLength) {
    return content;
  }

  const omission = "...";
  let separator = "";

  switch (truncatePosition) {
    case "start":
      separator = omission;
      return content.slice(contentLength - maxLength + omission.length);
    case "middle":
      const firstHalf = Math.ceil(maxLength / 2);
      const secondHalf = Math.floor(maxLength / 2);
      separator = omission;
      return (
        content.slice(0, firstHalf) +
        separator +
        content.slice(contentLength - secondHalf)
      );
    case "end":
      separator = omission;
      return content.slice(0, maxLength - separator.length) + separator;
    default:
      throw new Error(`Invalid truncate position: ${truncatePosition}`);
  }
}

export interface CopyProps {
  content: string | null | undefined;
  maxLength?: number; // optional prop for maximum length
  shouldBreak?: boolean; // optional prop for whether to break or not
  width?: string; // optional prop for setting width
  truncatePosition?: "start" | "middle" | "end"; // optional prop for where to truncate the content
}

const styles = {
  container: (shouldBreak: boolean, width?: string) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: shouldBreak ? "break-spaces" : "nowrap", // use "break-spaces" to allow line breaks
    width: width || "auto", // set width to specified value or "auto"
  }),
};

export const CopyToClipboard: React.FC<CopyProps> = ({
  content,
  maxLength = Infinity,
  shouldBreak = false,
  width,
  truncatePosition = "end",
}) => {
  const [copiedContent, setCopiedContent] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleCopy = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (content) {
      navigator.clipboard.writeText(content);
      setCopiedContent(content);
      setShowSnackbar(true);
    }
  };

  const truncatedContent = getTruncatedContent(
    content,
    maxLength,
    truncatePosition
  );

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <Box
        sx={styles.container(shouldBreak, width)}
        onClick={handleCopy}
        title={content || ""}
      >
        {truncatedContent}
      </Box>
      <Tooltip title="Copy to clipboard" placement="top">
        <IconButton
          size="small"
          onClick={handleCopy}
          sx={{ svg: { width: "16px", height: "16px" } }}
        >
          <FileCopyOutlined />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Copied {copiedContent} to clipboard!
        </Alert>
      </Snackbar>
    </Stack>
  );
};
