import {
  combineReducers,
  configureStore,
  ReducersMapObject,
} from "@reduxjs/toolkit";
import { meetupsReducer, MeetupsState } from "./slices/meetupsSlice";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { playersReducer, PlayersState } from "./slices/playersSlice";
import {
  applicationsReducer,
  ApplicationsState,
} from "./slices/applicationsSlice";
import { Reducer } from "react";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  meetups: meetupsReducer,
  players: playersReducer,
  applications: applicationsReducer,
});

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;
