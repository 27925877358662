import { DeleteForever, Send } from "@mui/icons-material";
import { DeprecatedEntityItem } from "../components/EntityTable/DeprecatedActionEntity";
import {
  deprecatedCreateEntityFieldMeta,
  DeprecatedEntityFieldType,
} from "../components/EntityTable/DeprecatedEntityForm";
import {
  DeprecatedEntityTable,
  deprecatedRenderColumn,
  columnCopy,
  columnFormatApplicant,
  columnFormatDate,
} from "../components/EntityTable/DeprecatedEntityTable";
import * as yup from "yup";
import { UserEmailTemplate } from "@pastukh-dm/wboard-games";

export const validationSchema = yup.object({
  userId: yup.string().required(),
  applicationId: yup.string().nullable(),
  template: yup
    .string()
    .oneOf(Object.values(UserEmailTemplate))
    .required()
    .meta(
      deprecatedCreateEntityFieldMeta(DeprecatedEntityFieldType.AUTOCOMPLETE, {
        options: Object.values(UserEmailTemplate).map((value) => ({ value })),
      })
    ),
  templateParams: yup.string(),
  messageId: yup.string(),
});

export const UserEmailsPage = () => {
  return (
    <>
      <DeprecatedEntityTable
        entityName="userEmails"
        apiUrls={{
          create: "/userEmails",
          read: "/userEmails",
          update: "/userEmails/:id",
          delete: "/userEmails/:id",
        }}
        columns={[
          deprecatedRenderColumn("id", columnCopy({ maxLength: 8 })),
          deprecatedRenderColumn("user", columnFormatApplicant),
          deprecatedRenderColumn("applicationId", columnCopy({ maxLength: 8 })),
          "template",
          // renderColumn("templateParams", columnCopy({ maxLength: 8 })),
          // "provider",
          deprecatedRenderColumn("messageId", columnCopy({ maxLength: 8 })),
          deprecatedRenderColumn("error", columnCopy({ maxLength: 8 })),
          // renderColumn("createdAt", columnFormatDate),
          deprecatedRenderColumn("updatedAt", columnFormatDate),
        ]}
        datatableOptions={{
          sortOrder: { name: "updatedAt", direction: "desc" },
        }}
        validationSchema={validationSchema as any}
        itemActions={[
          {
            actionName: "Send",
            url: "/userEmails/:id/send",
            icon: <Send />,
            hide: (
              item: DeprecatedEntityItem<{ messageId?: null | string }>
            ) => {
              return !!item.messageId;
            },
          },
        ]}
        multipleActions={[
          {
            actionName: "Send",
            url: "/userEmails/send",
            icon: <Send />,
          },
          {
            actionName: "Delete",
            url: "/userEmails",
            method: "DELETE",
            icon: <DeleteForever />,
          },
        ]}
      />
    </>
  );
};
