import {
  Typography,
  Table,
  TableRow,
  TableCell,
  Chip,
  TableHead,
} from "@mui/material";
import { ApplicationWith, DepositStatus } from "@pastukh-dm/wboard-games";
import { depositStatusRecord } from "src/pages/meetups/old/MeetupDepositsTab";

export const TotalDeposits = ({
  applications,
}: {
  applications: ApplicationWith<"deposit">[];
}) => {
  const getSumByDepositStatus = (status: DepositStatus): number => {
    if (!applications) return 0;

    const filteredApplications = applications.filter(
      (application) => application.deposit?.status === status
    );

    return filteredApplications.reduce(
      (total, application) => total + application.deposit!.amount,
      0
    );
  };
  const getCountByDepositStatus = (status: DepositStatus): number => {
    if (!applications) return 0;

    const filteredApplications = applications.filter(
      (application) => application.deposit?.status === status
    );

    return filteredApplications.length;
  };

  return (
    <>
      <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
        Total{" "}
      </Typography>
      <Table size="small" sx={{ width: "400px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell align="right">Count</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        {Object.values(DepositStatus).map((status) => (
          <TableRow>
            <TableCell>
              <Chip
                size="small"
                label={status}
                icon={depositStatusRecord[status]?.icon}
                sx={{
                  background: depositStatusRecord[status]?.bgColor,
                  color: depositStatusRecord[status]?.color,
                  ".MuiChip-icon": {
                    color: depositStatusRecord[status]?.color,
                  },
                }}
              />
            </TableCell>
            <TableCell align="right">
              <b>{getCountByDepositStatus(status)}</b>
            </TableCell>
            <TableCell align="right">
              <b>{getSumByDepositStatus(status)} PLN</b>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  );
};
