import axios from "axios";
import { useNotification } from "../../hooks/useNotification";
import { AppBaseDialog } from "../dialogs/AppBaseDialog";
import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

type Props = {
  deleteUrl: string;
  entityName: string;
  item: any;
  onSuccess: () => void;
};

/** @deprecated */
export const DeprecatedDeleteEntity = ({
  deleteUrl,
  entityName,
  item,
  onSuccess,
}: Props) => {
  const [open, setOpen] = useState(false);

  function handleButtonClick(e: React.MouseEvent) {
    e.stopPropagation();
    setOpen(true);
  }

  const { showNotification } = useNotification();
  const onDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const res = await axios.delete<{ message: string }>(
        item?.id ? deleteUrl.replace(":id", item.id) : deleteUrl
      );
      showNotification({
        severity: "success",
        message: res.data.message,
      });
      onSuccess();
      setOpen(false);
    } catch (e: any) {
      showNotification({
        severity: "error",
        message: e?.response?.data?.error,
      });
    }
  };

  return (
    <>
      <IconButton onClick={handleButtonClick} color="error">
        <DeleteForeverIcon />
      </IconButton>
      <AppBaseDialog
        open={open}
        title={`Delete item?`}
        maxWidth="sm"
        content={
          <>
            <pre>{JSON.stringify(item, null, "\t")}</pre>
            <Button color="error" onClick={onDelete}>
              Delete
            </Button>
          </>
        }
      />
    </>
  );
};
// import { useCallback, useMemo } from "react";
// import { ButtonDialog } from "../../../components/ButtonDialog";
// import { useNotification } from "../../../hooks/useNotification";
// import { useAppDispatch, useAppSelector } from "../../../store/hooks";
// import { Meetup, meetupsActions } from "../../../store/slices/meetupsSlice";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import { applicationsSelectors } from "../../../store/slices/applicationsSlice";

// type Props = {
//   meetup: Meetup;
// };

// export const RemoveMeetupButtonDialog = ({ meetup }: Props) => {
//   const { showNotification } = useNotification();
//   const dispatch = useAppDispatch();
//   const handleRemove = useCallback(
//     (item: Meetup) => {
//       dispatch(meetupsActions.meetupRemoved(item.id));
//       showNotification({
//         severity: "error",
//         message: `"${item.name}" removed`,
//       });
//     },
//     [dispatch]
//   );

//   const applicationCount = useAppSelector((state) =>
//     applicationsSelectors.selectTotalBy(state, { meetupId: meetup.id })
//   );

//   const disabled = useMemo(() => applicationCount > 0, [applicationCount]);

//   return (
//     <ButtonDialog
//       button={{
//         props: { color: "error", disabled },
//         icon: <DeleteForeverIcon />,
//       }}
//       dialog={{
//         title: `Remove "${meetup.name}" meetup?`,
//         submitButton: { text: "Remove", props: { color: "error" } },
//         onSubmit: () => handleRemove(meetup),
//       }}
//     />
//   );
// };
