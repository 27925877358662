import { Email, CancelOutlined, RestartAlt, Edit } from "@mui/icons-material";
import {
  ApplicationModel,
  MeetupModel,
  ApplicationStatus,
  UserModel,
} from "@pastukh-dm/wboard-games";
import { Method } from "axios";
import { useState, ReactNode } from "react";
import {
  EntityDialogFields,
  FieldType,
} from "src/components/dialogs/EntityDialog/EntityDialogForm";
import { useFetch } from "src/hooks/useFetch";
import { canTransitionStatus } from "src/utils/StatusFlow";
import { z } from "zod";

export enum MenuAction {
  NONE,
  SEND_EMAIL,
  CANCEL,
  EDIT,
  RESET,
}

export const useMeetupApplicationMenu = ({
  application,
  meetup,

  allMeetups,
  allUsers,
}: {
  application: ApplicationModel;
  meetup: MeetupModel;

  allMeetups: MeetupModel[];
  allUsers: UserModel[];
}) => {
  const [action, setAction] = useState<MenuAction>(MenuAction.NONE);
  const menuItems: {
    action: MenuAction;
    icon: ReactNode;
    text: string;
    disabled?: boolean;
  }[] = [
    {
      action: MenuAction.SEND_EMAIL,
      icon: <Email fontSize="small" />,
      text: "Send email",
    },
    {
      action: MenuAction.CANCEL,
      icon: <CancelOutlined fontSize="small" />,
      text: "Cancel",
      disabled: !canTransitionStatus(
        application.status,
        ApplicationStatus.CANCELED
      ),
    },
    {
      action: MenuAction.EDIT,
      icon: <Edit fontSize="small" />,
      text: "Edit",
    },
    {
      action: MenuAction.RESET,
      icon: <RestartAlt fontSize="small" />,
      text: "Reset",
      disabled: !canTransitionStatus(
        application.status,
        ApplicationStatus.APPLIED
      ),
    },
  ];

  const dialogParamsByAction: Record<
    MenuAction,
    {
      // open: boolean;
      title: string;
      description?: string;
      fields?: EntityDialogFields;
      initialValues?: { [key: string]: any };
      submitMethod?: Method;
      submitUrl: string;
      submitText?: string;
      // submitColor?: MaterialColor;
      onSuccess?: (item: any) => void;
      // onClose: () => void;
    }
  > = {
    [MenuAction.NONE]: {
      title: "",
      submitUrl: "/",
    },
    [MenuAction.SEND_EMAIL]: {
      title: "Send email",
      fields: {
        subject: {
          type: FieldType.TEXT,
          defaultValue: "SUBJECT",
          validation: z.string().min(1),
        },
        title: {
          type: FieldType.TEXT,
          defaultValue: "TITLE",
          validation: z.string().min(1),
        },
        body: {
          type: FieldType.TEXT,
          defaultValue: "BODY",
          validation: z.string().min(1),
        },
        includeApplication: {
          type: FieldType.CHECKBOX,
          defaultValue: true,
          validation: z.boolean(),
        },
      },
      initialValues: {
        subject: `Last chance to confirm - ${meetup.name}`,
        title: "Last chance to confirm",
        body: "Confirm your application for the meetup quickly. Otherwise it will be canceled.",
        includeApplication: true,
      },
      submitUrl: `/applications/${application.id}/sendEmail`,
      submitText: "Send",
    },
    [MenuAction.CANCEL]: {
      title: "Cancel",
      fields: {
        reason: {
          type: FieldType.TEXT,
          validation: z.string().min(1),
        },
      },
      initialValues: {
        reason: `you didn't confirm`,
      },
      submitUrl: `/applications/${application.id}/cancel`,
    },
    [MenuAction.EDIT]: {
      title: "Edit",
      fields: {
        meetupId: {
          type: FieldType.AUTOCOMPLETE,
          options:
            allMeetups
              ?.sort((a, b) => (a.startAt > b.startAt ? -1 : 1))
              .map((item) => ({ label: item.name, value: item.id })) || [],
        },
        userId: {
          type: FieldType.AUTOCOMPLETE,
          options:
            allUsers?.map((user) => ({
              label: `${user.name || "-"} (${user.firstName || "-"} ${
                user.lastName || "-"
              }) – ${user.email}`,
              value: user.id,
            })) || [],
        },
        status: {
          type: FieldType.AUTOCOMPLETE,
          options: Object.values(ApplicationStatus).map((value) => ({
            value,
          })),
        },
      },
      initialValues: {
        meetupId: application.meetupId,
        userId: application.userId,
        status: application.status,
      },
      submitMethod: "PATCH",
      submitUrl: `/applications/${application.id}`,
    },
    [MenuAction.RESET]: {
      title: "Reset",
      submitUrl: `/applications/${application.id}/applySoft`,
    },
  };

  return {
    dialogParams: dialogParamsByAction[action],
    menuItems,
    action,
    setAction,
  };
};
