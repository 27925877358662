import {
  Alert,
  Button,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { ReactNode, useMemo, useState } from "react";
import { AnyObjectSchema } from "yup";
import { useNotification } from "../../hooks/useNotification";
import { useRequest } from "../../hooks/useRequest";
import { AppBaseDialog } from "../dialogs/AppBaseDialog";
import { JsonTable } from "../JsonTable";
import { DeprecatedEntityForm } from "./DeprecatedEntityForm";

/** @deprecated */
export type DeprecatedActionEntityProps = {
  url: string;
  method?: "post" | "delete";
  entityName?: string;
  item: DeprecatedEntityItem;
  onSuccess: () => void;
  icon: ReactNode;
  validationSchema?: AnyObjectSchema | any;
  actionName: string;
  defaultValues?: (e: any) => any;

  hide?: (item: any) => boolean;
  disable?: (item: any) => boolean;
};

/** @deprecated */
export type DeprecatedEntityItem<
  T = {
    [key: string]: any;
  }
> = {
  id: string;
} & T;

/** @deprecated */
export const DeprecatedActionEntity = ({
  url,
  entityName,
  actionName,
  item,
  onSuccess,
  icon,
  hide,
  method,
  disable,
  validationSchema,
  defaultValues,
}: DeprecatedActionEntityProps) => {
  const [open, setOpen] = useState(false);

  function handleButtonClick() {
    setOpen(true);
  }

  const { showNotification } = useNotification();
  const [error, setError] = useState<string | false>(false);
  const onSubmit = async (data?: any) => {
    try {
      setError(false);
      const res = await axios<{ message: string }>({
        url: item?.id ? url.replace(":id", item.id) : url,
        method: method || "post",
        data,
      });
      showNotification({
        severity: "success",
        message: res.data.message,
      });
      onSuccess();
      setOpen(false);
    } catch (e: any) {
      const message =
        e?.response?.data?.error || e?.response?.data?.message || "Error";
      setError(message);
      showNotification({
        severity: "error",
        message,
      });
    }
  };

  const shouldHide = useMemo(() => {
    if (hide) {
      return hide(item);
    }
    return false;
  }, [hide, item]);
  const shouldDisable = useMemo(() => {
    if (disable) {
      return disable(item);
    }
    return false;
  }, [disable, item]);

  if (shouldHide) {
    return null;
  }

  return (
    <>
      <Tooltip title={actionName}>
        <IconButton onClick={handleButtonClick} disabled={shouldDisable}>
          {icon}
        </IconButton>
      </Tooltip>
      <AppBaseDialog
        open={open}
        title={`${actionName} ${entityName}?`}
        maxWidth="sm"
        content={
          <>
            {validationSchema ? (
              <DeprecatedEntityForm
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                defaultValues={defaultValues?.(item)}
              />
            ) : (
              <>
                <Typography mb={4}>{item?.name || item?.id}</Typography>
                <Button variant="contained" onClick={() => onSubmit()}>
                  {actionName}
                </Button>
                {error && (
                  <Alert severity="error" sx={{ mt: 4 }}>
                    {error}
                  </Alert>
                )}
              </>
            )}
          </>
        }
      />
    </>
  );
};
