import { Button } from "@mui/material";
import { GameModel } from "@pastukh-dm/wboard-games";
import {
  DeprecatedEntityTable,
  DeprecatedDataEntityTable,
  DeprecatedEntityTableProps,
} from "../../components/EntityTable/DeprecatedEntityTable";
import { useRequest } from "../../hooks/useRequest";
import { gameValidator } from "../../validators/gameValidators";
import { gameColumns } from "../columns/gameColumns";

export const GamesPage = () => {
  const { request: submit, isLoading } = useRequest({
    url: "/games/updateGeekInfo",
  });
  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => submit()}
        disabled={isLoading}
        sx={{ mb: 2 }}
      >
        Update geek info
      </Button>
      <GamesTable />
    </>
  );
};

export const GamesTable = () => {
  const props = useGamesTable();
  return <DeprecatedEntityTable {...props} />;
};

export const GamesDataTable = ({
  data,
  singleItem,
}: {
  data: GameModel[];
  singleItem?: boolean;
}) => {
  const props = useGamesTable();
  return (
    <DeprecatedDataEntityTable {...props} data={data} singleItem={singleItem} />
  );
};

export const useGamesTable = (): DeprecatedEntityTableProps<GameModel> => {
  const sharedProps: DeprecatedEntityTableProps<GameModel> = {
    entityName: "Game",
    validationSchema: gameValidator,
    apiUrls: {
      create: "/games",
      read: "/games",
      update: "/games/:id",
      delete: "/games/:id",
    },
    columns: gameColumns,
    datatableOptions: {
      sortOrder: { name: "updatedAt", direction: "desc" },
    },
    itemUrl: "/games/:id",
  };
  return { ...sharedProps };
};
