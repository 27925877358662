import React, { useState } from "react";
import axios from "axios";
import { Alert, Button, Card, CardContent, Container } from "@mui/material";

export const LoginPage = () => {
  return <LoginForm />;
};

interface FormData {
  email: string;
}

const LoginForm = () => {
  const [formData, setFormData] = useState<FormData>({ email: "" });
  const [error, setError] = useState<string>();
  const [message, setMessage] = useState<string>();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(undefined);
    try {
      const res = await axios.post("/auth/login", { email: formData.email });
      setMessage(res.data?.message || "Success");
    } catch (e: any) {
      setError(e?.response?.data?.message || "Something went wrong");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Container sx={{ my: 4 }} maxWidth="xs">
      {message ? (
        <Alert severity="success">{message}</Alert>
      ) : (
        <Card elevation={24}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                autoFocus
              />
              <Button type="submit">Login</Button>
              {error && <Alert severity="error">{error}</Alert>}
            </form>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};
