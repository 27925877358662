import { EntityAdapter, EntityState } from "@reduxjs/toolkit";
import { RootState } from "./RootState";
import { Application } from "./slices/applicationsSlice";

// getSelectors<V>(selectState: (state: V) => EntityState<T>): EntitySelectors<T, V>;
export const makeAdapterSelectors = <T>(
  adapter: EntityAdapter<T>,
  selectState: (state: RootState) => EntityState<T>
) => {
  const adapterSelectors = adapter.getSelectors(selectState);

  const selectBy = (state: RootState, params: Partial<T>) =>
    adapterSelectors
      .selectAll(state)
      .filter((entity) =>
        Object.entries(params).every(
          ([paramKey, paramValue]) => entity[paramKey as keyof T] === paramValue
        )
      );

  const selectTotalBy = (...args: Parameters<typeof selectBy>) =>
    selectBy(...args).length;

  return {
    ...adapterSelectors,
    selectBy,
    selectTotalBy,
  };
};
