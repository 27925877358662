import { MUIDataTableColumnDef } from "mui-datatables";
import {
  deprecatedRenderColumn,
  columnCopy,
  columnFormatDate,
} from "../../components/EntityTable/DeprecatedEntityTable";

export const gameColumns: MUIDataTableColumnDef[] = [
  deprecatedRenderColumn("id", columnCopy({ maxLength: 12 })),
  "geekId",
  "name",
  "rank",
  "weight",
  deprecatedRenderColumn("description", columnCopy({ maxLength: 30 })),
  deprecatedRenderColumn("createdAt", columnFormatDate),
  deprecatedRenderColumn("updatedAt", columnFormatDate),
];
