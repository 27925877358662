import * as yup from "yup";
import {
  deprecatedCreateEntityFieldMeta,
  DeprecatedEntityFieldType,
} from "../../components/EntityTable/DeprecatedEntityForm";
import {
  columnCopy,
  columnFormatDate,
  DeprecatedEntityTableProps,
  deprecatedRenderColumn,
} from "../../components/EntityTable/DeprecatedEntityTable";
import { Deposit, DepositStatus } from "./Deposit";

export const useDepositsTable = (): DeprecatedEntityTableProps<Deposit> => {
  const sharedProps: DeprecatedEntityTableProps<Deposit> = {
    entityName: "Deposit",
    validationSchema: yup.object().shape({
      amount: yup.number().integer().min(0).required(),
      status: yup
        .string()
        .oneOf(Object.values(DepositStatus))
        .required()
        .meta(
          deprecatedCreateEntityFieldMeta(
            DeprecatedEntityFieldType.AUTOCOMPLETE,
            {
              options: Object.values(DepositStatus).map((value) => ({
                value,
              })),
            }
          )
        ),
      applicationId: yup.string().required(),
    }) as any,
    apiUrls: {
      create: "/deposits",
      read: "/deposits",

      update: "/deposits/:id",
      delete: "/deposits/:id",
    },
    columns: [
      deprecatedRenderColumn("id", columnCopy({ maxLength: 12 })),
      "meetupName",
      "userName",
      "amount",
      "status",
      deprecatedRenderColumn("createdAt", columnFormatDate),
      deprecatedRenderColumn("updatedAt", columnFormatDate),
    ],
    datatableOptions: {
      sortOrder: { name: "updatedAt", direction: "desc" },
    },
    // itemUrl: "/meetups/:id",
  };
  return { ...sharedProps };
};
