import { Alert, Box, Stack, Typography } from "@mui/material";
import { MeetupModel } from "@pastukh-dm/wboard-games";
import { useParams } from "react-router-dom";
import { AppTabs } from "src/components/AppTabs";
import { ButtonDialog } from "src/components/dialogs/ButtonDialog";
import { DetailsTable } from "src/components/DetailsTable";
import { FetchRender } from "src/components/FetchRender";
import { PageTitle } from "src/components/PageTitle";
import { formatDatetime } from "src/helpers/formatHelper";
import { useFetch } from "src/hooks/useFetch";
import { useNotification } from "src/hooks/useNotification";
import { useRequest } from "src/hooks/useRequest";
import { DeleteMeetupButton } from "../components/DeleteMeetupButton";
import { EditMeetupButton } from "../components/EditMeetupButton";
import { MeetupApplicationsTab } from "./MeetupApplicationsTab";
import { MeetupDepositsTab } from "./MeetupDepositsTab";
import { MeetupGamesTab } from "./MeetupGamesTab";
import { MeetupPlanTab } from "./MeetupPlanTab";

export const MeetupDetailsPage = () => {
  const { id } = useParams();
  const {
    data: meetup,
    error,
    isLoading,
  } = useFetch<MeetupModel & { _count: { applications: number } }>({
    url: `/meetups/${id}`,
  });
  const { request: notifySubscribers, isLoading: isLoadingNotifySubscribers } =
    useRequest({ url: `/meetups/${id}/notify-subscribers` });
  const { showNotification } = useNotification();
  const handleNotifySubscribers = async () => {
    await notifySubscribers();
    showNotification({
      severity: "success",
      message: "All subscribers are notified",
    });
    return true;
  };

  return (
    <>
      <PageTitle text={meetup?.name || `Meetup: ${id}`} />
      <FetchRender data={meetup} error={error} isLoading={isLoading}>
        {meetup && (
          <AppTabs
            tabs={[
              {
                key: "applications",
                count: meetup._count.applications,
                content: <MeetupApplicationsTab meetup={meetup} />,
              },
              {
                key: "plan",
                content: <MeetupPlanTab meetup={meetup} />,
              },
              {
                key: "games",
                content: <MeetupGamesTab meetup={meetup} />,
              },
              {
                key: "deposits",
                content: <MeetupDepositsTab meetup={meetup} />,
              },
              {
                key: "meetup",
                content: (
                  <>
                    <DetailsTable
                      rows={{
                        id: true,
                        name: true,
                        shortId: true,

                        level: true,
                        address: true,
                        startAt: (val) => formatDatetime(val),
                        applyFrom: (val) => formatDatetime(val),
                        applyTo: (val) => formatDatetime(val),
                        chatLink: (val) => (
                          <a href={val} target="_blank">
                            {val}
                          </a>
                        ),
                        createdAt: (val) => formatDatetime(val),
                        updatedAt: (val) => formatDatetime(val),
                        notified: (val) =>
                          val === true ? (
                            "TRUE"
                          ) : (
                            <ButtonDialog
                              text="Notify subscribers"
                              variant="outlined"
                              color="secondary"
                              disabled={meetup?.notified}
                              dialogTitle="Are you sure?"
                              onSubmit={handleNotifySubscribers}
                              isLoading={isLoadingNotifySubscribers}
                            >
                              <Alert severity="warning">
                                All subscribers will receive emails about{" "}
                                <Typography
                                  display="inline"
                                  noWrap
                                  fontWeight={700}
                                >
                                  {meetup?.name}
                                </Typography>
                              </Alert>
                            </ButtonDialog>
                          ),
                      }}
                      data={meetup}
                    />
                    <Stack sx={{ mt: 4 }} spacing={2}>
                      <EditMeetupButton item={meetup} />
                      <DeleteMeetupButton
                        meetupName={meetup.name}
                        meetupId={meetup.id}
                      />
                    </Stack>
                  </>
                ),
              },
            ]}
          />
        )}
      </FetchRender>
    </>
  );
};
