import { ErrorMessage } from "@hookform/error-message";
import {
  Box,
  TextField,
  Button,
  Paper,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  FormControl,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  Controller,
  ControllerRenderProps,
  DeepPartial,
  FieldErrors,
  FieldValue,
  FieldValues,
  Path,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import * as Joi from "joi";
import { MaterialColor } from "src/types/MaterialColor";
import { ReactElement } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DATETIME_FORMAT } from "src/helpers/formatHelper";
import { DeprecatedEntityFieldSelectOption } from "../../EntityTable/DeprecatedEntityForm";

type Props<T extends FieldValues> = {
  onSubmit: (data: T) => void;
  fields: EntityDialogFields;
  initialValues?: DeepPartial<T>;
  submitColor?: MaterialColor;
  submitText?: string;
};

export const EntityDialogForm = <T extends FieldValues>({
  initialValues,
  onSubmit,
  fields,

  submitColor,
  submitText,
}: Props<T>) => {
  const validationSchema = z.object(
    Object.fromEntries(
      Object.entries(fields).map(([key, value]) => [
        key,
        value.validation || z.any(),
      ])
    )
  );
  const defaultValues = {
    ...Object.fromEntries(
      Object.entries(fields).map(([key, value]) => [key, value.defaultValue])
    ),
    ...initialValues,
  } as DeepPartial<T>;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const onSubmitHandler: SubmitHandler<T> = (data) => {
    onSubmit(data);
  };
  const all = watch();

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      {/* <FormPayload data={errors} /> */}

      {Object.entries(fields).map(([fieldName, fieldDefinition]) => (
        <Box sx={{ my: 2 }} key={fieldName}>
          <Controller
            key={fieldName}
            name={fieldName as Path<T>}
            control={control}
            render={({ field }) =>
              renderFieldByType[fieldDefinition.type]({
                name: fieldName as FieldValue<T>,
                errors,
                field,
                definition: fieldDefinition,
              })
            }
          />
        </Box>
      ))}
      <Button
        variant="contained"
        type="submit"
        color={submitColor}
        onClick={handleSubmit(onSubmitHandler)}
      >
        {submitText || "Submit"}
      </Button>
      <FormPayload data={all} />
    </form>
  );
};

const FormPayload = ({ data }: { data: FieldValues }) => (
  <Box sx={{ fontSize: "12px" }}>
    <Paper
      variant="outlined"
      sx={{ mt: 4, py: 0.5, px: 2, background: grey[100] }}
    >
      <Box component="pre" sx={{ whiteSpace: "pre-wrap" }}>
        {JSON.stringify(data, null, "  ")}
      </Box>
    </Paper>
  </Box>
);

export enum FieldType {
  SELECT = "SELECT",
  AUTOCOMPLETE = "AUTOCOMPLETE",
  AUTOCOMPLETE_MULTIPLE = "AUTOCOMPLETE_MULTIPLE",
  TEXT = "TEXT",
  ARRAY_TEXT = "ARRAY_TEXT",
  CHECKBOX = "CHECKBOX",
  DATETIME = "DATETIME",
}
export type EntityDialogFieldBase = {
  type: FieldType;
  defaultValue?: any;
  validation?: z.ZodTypeAny;
  disabled?: boolean;
};
export type EntityDialogFieldSelect = EntityDialogFieldBase & {
  type: FieldType.SELECT;
  defaultValue?: string;
};
export type EntityDialogFieldAutocomplete = EntityDialogFieldBase & {
  type: FieldType.AUTOCOMPLETE;
  defaultValue?: string;
  options: EntityDialogFieldSelectOption[];
};
export type EntityDialogFieldAutocompleteMultiple = EntityDialogFieldBase & {
  type: FieldType.AUTOCOMPLETE_MULTIPLE;
  defaultValue?: string[];
  options: EntityDialogFieldSelectOption[];
  groupOptions?: boolean;
  renderOption?: (
    option: EntityDialogFieldSelectOption
    // state: AutocompleteRenderOptionState,
    // ownerState: AutocompleteOwnerState<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  ) => React.ReactNode;
};
export type EntityDialogFieldTextArray = EntityDialogFieldBase & {
  type: FieldType.TEXT;
  defaultValue?: string;
};
export type EntityDialogFieldCheckbox = EntityDialogFieldBase & {
  type: FieldType.CHECKBOX;
  defaultValue?: boolean;
};
export type EntityDialogFieldDatetime = EntityDialogFieldBase & {
  type: FieldType.DATETIME;
  defaultValue?: Date;
};

export type EntityDialogField =
  | EntityDialogFieldSelect
  | EntityDialogFieldAutocomplete
  | EntityDialogFieldAutocompleteMultiple
  | EntityDialogFieldTextArray
  | EntityDialogFieldCheckbox
  | EntityDialogFieldDatetime;

export type EntityDialogFields = {
  [key: string]: EntityDialogField;
};

export type EntityDialogFieldSelectOption<T = string, G = string> = {
  label?: string;
  value: T;
  group?: G;
};

const renderFieldByType: Record<
  FieldType,
  <T extends FieldValues>(props: {
    name: FieldValue<T>;
    errors: FieldErrors<T>;
    field: ControllerRenderProps<T>;
    definition: EntityDialogField;
  }) => ReactElement
> = {
  [FieldType.AUTOCOMPLETE]: ({ name, errors, field, definition }) => {
    definition = definition as EntityDialogFieldAutocomplete;
    return (
      <FormControl fullWidth error={!!errors[name]}>
        <Autocomplete
          {...field}
          onChange={(e, value) => {
            field.onChange(value?.value);
          }}
          value={definition.options.find(
            (option) => option.value === field.value
          )}
          options={definition.options}
          getOptionLabel={(option) => option.label || option.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label={name}
              variant="outlined"
              fullWidth
              error={!!errors[name]}
              helperText={<ErrorMessage errors={errors} name={name} />}
            />
          )}
          disabled={definition.disabled}
        />
      </FormControl>
    );
  },
  [FieldType.AUTOCOMPLETE_MULTIPLE]: ({ name, errors, field, definition }) => {
    const def = definition as EntityDialogFieldAutocompleteMultiple;
    return (
      <FormControl fullWidth error={!!errors[name]}>
        <Autocomplete
          {...field}
          onChange={(e, value) => {
            field.onChange(value?.map((val) => val.value));
          }}
          value={def.options.filter((option) =>
            field.value?.includes(option.value)
          )}
          // renderOption={
          //   !!def.renderOption
          //     ? (props, option) => (
          //         <>
          //           <li {...props}>{def.renderOption?.(option)}</li>
          //         </>
          //       )
          //     : undefined
          // }
          groupBy={def.groupOptions ? (option) => option.group! : undefined}
          options={def.options}
          multiple
          disableCloseOnSelect
          getOptionLabel={(option) => option.label || option.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label={name}
              variant="outlined"
              fullWidth
              error={!!errors[name]}
              helperText={<ErrorMessage errors={errors} name={name} />}
            />
          )}
          disabled={definition.disabled}
        />
      </FormControl>
    );
  },
  [FieldType.CHECKBOX]: ({ name, errors, field, definition }) => (
    <FormControlLabel
      label={name}
      control={<Checkbox checked={field.value} onChange={field.onChange} />}
      disabled={definition.disabled}
    />
  ),
  [FieldType.SELECT]: () => <>select</>,
  [FieldType.TEXT]: ({ name, errors, field, definition }) => (
    <TextField
      label={name}
      variant="outlined"
      fullWidth
      error={!!errors[name]}
      helperText={<ErrorMessage errors={errors} name={name} />}
      {...field}
      disabled={definition.disabled}
    />
  ),
  [FieldType.ARRAY_TEXT]: () => <>array text</>,
  [FieldType.DATETIME]: ({ name, errors, field, definition }) => (
    <DateTimePicker
      renderInput={(props) => {
        return (
          <>
            <TextField
              variant="outlined"
              // required
              {...(props as any)}
              error={!!errors[name]}
              fullWidth
              helperText={<ErrorMessage errors={errors} name={name} />}
            />
          </>
        );
      }}
      label={name}
      disableMaskedInput
      inputFormat={DATETIME_FORMAT}
      {...field}
      disabled={definition.disabled}
    />
  ),
};
