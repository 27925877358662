import { Alert, Button, Typography } from "@mui/material";
import { AppBaseDialog } from "./AppBaseDialog";

import axios, { Method } from "axios";
import { useState } from "react";
import { useNotification } from "src/hooks/useNotification";
import { DeepPartial, FieldValues } from "react-hook-form";
import { MaterialColor } from "src/types/MaterialColor";
import {
  EntityDialogFields,
  EntityDialogForm,
} from "./EntityDialog/EntityDialogForm";

export type FormDialogProps<T extends FieldValues> = {
  open: boolean;
  title: string;
  description?: string;
  fields?: EntityDialogFields;
  initialValues?: DeepPartial<T>;
  submitMethod?: Method;
  submitUrl: string;
  submitText?: string;
  submitColor?: MaterialColor;
  onSuccess?: (item: any) => void;
  onClose: () => void;
};

export const FormDialog = <T extends FieldValues>({
  initialValues,
  onSuccess,
  submitUrl,
  submitMethod,
  title,
  description,
  open,
  onClose,
  submitText,
  submitColor,
  fields,
}: FormDialogProps<T>) => {
  const { showNotification } = useNotification();
  const [error, setError] = useState<string | false>(false);

  const onSubmit = async (data: any) => {
    try {
      setError(false);
      const res = await axios<{ message: string; item: any }>({
        method: submitMethod || "POST",
        url: submitUrl,
        data,
      });
      showNotification({
        severity: "success",
        message: res.data?.message || "Success",
      });
      onSuccess?.(res.data?.item);
      onClose();
    } catch (e: any) {
      const message =
        e?.response?.data?.error || e?.response?.data?.message || "Error";
      setError(message);
      showNotification({
        severity: "error",
        message,
      });
    }
  };

  return (
    <AppBaseDialog
      open={open}
      title={title}
      content={
        <>
          {description && <Typography>{description}</Typography>}
          {fields ? (
            <EntityDialogForm
              onSubmit={onSubmit}
              fields={fields}
              initialValues={initialValues}
              submitText={submitText}
              submitColor={submitColor}
            />
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => onSubmit({})}
                color={submitColor}
              >
                {submitText || "Submit"}
              </Button>
            </>
          )}

          {error && (
            <Alert severity="error" sx={{ mt: 4 }}>
              {error}
            </Alert>
          )}
        </>
      }
      onClose={onClose}
    />
  );
};
