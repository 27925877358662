import { SvgIconComponent } from "@mui/icons-material";
import { Tooltip, Box, Typography } from "@mui/material";

interface CountWithIconProps {
  count: number;
  icon: SvgIconComponent;
  color?: "primary" | "secondary" | "warning" | "error";
  tooltip?: string;
}

export const CountWithIcon: React.FC<CountWithIconProps> = ({
  count,
  icon: Icon,
  color,
  tooltip,
}: CountWithIconProps) => {
  return (
    <Tooltip title={tooltip}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          color: `${color}.main`,
        }}
      >
        {Icon && <Icon fontSize="small" />}
        <Typography variant="body2">{count}</Typography>
      </Box>
    </Tooltip>
  );
};
