import { MeetupModel } from "@pastukh-dm/wboard-games";
import { AppTabs } from "../../../components/AppTabs";
import { MeetupPlanCooldownTab } from "./MeetupPlanCooldownTab";
import { MeetupPlanFinalTab } from "./MeetupPlanFinalTab";
import { MeetupPlanMainTab } from "./MeetupPlanMainTab";

type Props = { meetup: MeetupModel };

export const MeetupPlanTab = ({ meetup }: Props) => {
  return (
    <>
      <AppTabs
        color="secondary"
        searchPrefix="game"
        tabs={[
          {
            key: "Main",
            content: <MeetupPlanMainTab meetup={meetup} />,
          },
          {
            key: "Cooldown",
            content: <MeetupPlanCooldownTab meetup={meetup} />,
          },
          {
            key: "Final",
            content: <MeetupPlanFinalTab meetup={meetup} />,
          },
        ]}
      />
    </>
  );
};
