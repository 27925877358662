import { Typography } from "@mui/material";

type Props = {
  text: string;
};
export const PageTitle = ({ text }: Props) => {
  return (
    <Typography variant="h4" sx={{ mb: 4 }}>
      {text}
    </Typography>
  );
};
