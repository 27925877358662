import { MeetupLevel } from "@pastukh-dm/wboard-games";
import dayjs from "dayjs";
import {
  EntityDialogField,
  EntityDialogFields,
  FieldType,
} from "src/components/dialogs/EntityDialog/EntityDialogForm";
import * as z from "zod";

export const useMeetupFields = () => {
  const startAt = dayjs()
    .add(2, "week")
    .day(0)
    .hour(14)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate();

  const fields: { [key: string]: EntityDialogField } = {
    name: {
      type: FieldType.TEXT,
      defaultValue: "__th WBG _____ Meetup",
      validation: z.string().min(4),
    },
    shortId: {
      type: FieldType.TEXT,
      defaultValue: "WBG__",
      validation: z.string().min(4),
    },
    level: {
      type: FieldType.AUTOCOMPLETE,
      options: Object.values(MeetupLevel).map((value) => ({ value })),
      defaultValue: MeetupLevel.HEAVY,
      validation: z.nativeEnum(MeetupLevel),
    },
    address: {
      type: FieldType.TEXT,
      defaultValue: "La Lucy, (ul. Pereca 11)",
      validation: z.string().min(4),
    },
    startAt: {
      type: FieldType.DATETIME,
      defaultValue: startAt,
      validation: z.coerce.date(),
    },
    notified: {
      type: FieldType.CHECKBOX,
      defaultValue: false,
      validation: z.boolean(),
    },
    applyFrom: {
      type: FieldType.DATETIME,
      defaultValue: dayjs().toDate(),
      validation: z.coerce.date(),
    },
    applyTo: {
      type: FieldType.DATETIME,
      defaultValue: dayjs(startAt).subtract(6, "d").hour(22).toDate(),
      validation: z.coerce.date(),
    },
    chatLink: {
      type: FieldType.TEXT,
      defaultValue: "https://chat.whatsapp.com/___",
      validation: z.string().url(),
    },
  };

  return {
    fields,
  };
};
