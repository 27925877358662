import { GamesOnMeetupsWith } from "@pastukh-dm/wboard-games";
import { useFetch } from "../useFetch";

export type MeetupGame = GamesOnMeetupsWith<"game" | "user">;

export const useMeetupGamesFetcher = (meetupId: string) => {
  const { data, refetch } = useFetch<MeetupGame[]>({
    url: `/meetups/${meetupId}/games`,
  });

  return {
    meetupGames: data,
    refetchMeetupGames: refetch,
  };
};
