import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { generateId, GenerateIdPrefix } from "../../helpers/generateId";
import { RootState } from "../RootState";

export type Meetup = {
  id: string;
  createdAt?: string; // "2023-03-18T15:02:31.000Z";
  updatedAt?: string; // "2023-03-18T15:02:31.000Z";
} & MeetupPayload;

export type MeetupPayload = {
  // title: string;
  // url?: string;
  // startAt?: Date;
  // endAt?: Date;
  // minWeight?: number;
  // maxWeight?: number;
  // address?: string;
  // description?: string;
  name: string; //"51st WBG Heavy Meetup";
  address: string; //"address";
  level: "HEAVY" | "MEDIUM";
  startAt: string; // "2023-03-26T13:00:00.000Z";
  applyFrom: string; //"2023-03-17T00:00:00.000Z";
  applyTo: string; // "2023-04-28T00:00:00.000Z";
};

const meetupsAdapter = createEntityAdapter<Meetup>({
  selectId: (meetup) => meetup.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const meetupsSlice = createSlice({
  name: "meetups",
  initialState: meetupsAdapter.getInitialState(),
  reducers: {
    meetupCreated(state, action: PayloadAction<MeetupPayload>) {
      const meetup = {
        ...action.payload,
        id: generateId(GenerateIdPrefix.Meetup),
      };
      meetupsAdapter.addOne(state, meetup);
    },
    meetupUpdated: meetupsAdapter.updateOne,
    meetupRemoved: meetupsAdapter.removeOne,
    meetupSetAll: meetupsAdapter.setAll,
  },
});

export const meetupsReducer = meetupsSlice.reducer;
export const meetupsActions = meetupsSlice.actions;

export const meetupsSelectors = meetupsAdapter.getSelectors<RootState>(
  (state) => state.meetups
);

// And then use the selectors to retrieve values
// const allMeetups = meetupsSelectors.selectAll(store.getState());

export type MeetupsState = ReturnType<typeof meetupsAdapter.getInitialState>;
