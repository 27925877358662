import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonProps,
  DialogProps,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

export type AppBaseDialogProps = DialogProps & {
  title: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
  onClose?: () => void;
};

export const AppBaseDialog = ({
  title,
  content,
  actions,
  onClose,
  ...dialogProps
}: AppBaseDialogProps) => {
  const [open, setOpen] = useState(dialogProps.open);

  useEffect(() => {
    setOpen(dialogProps.open);
  }, [dialogProps.open]);

  function handleClose() {
    setOpen(false);
    onClose?.();
  }
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      {...dialogProps}
      open={open}
      onClose={handleClose}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && <DialogContent>{content}</DialogContent>}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
