import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useEffect } from "react";
import { createContext, useContext, useState } from "react";

export const LayoutNotification = () => {
  const { notification, severity, showNotification } = useContext(
    LayoutNotificationContext
  );
  return (
    <Snackbar
      open={!!notification}
      onClose={() => showNotification({ message: "" })}
    >
      <Alert severity={severity || "info"}>{notification}</Alert>
    </Snackbar>
  );
};

export const LayoutNotificationContext = createContext<{
  notification: string;
  severity?: AlertColor;
  showNotification: (params: {
    severity?: AlertColor;
    message: string;
  }) => void;
}>({ notification: "", severity: "info", showNotification: () => {} });
