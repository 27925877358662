import { MaterialColor } from "src/types/MaterialColor";
import * as yup from "yup";
import { BaseEntityDialog, BaseEntityDialogProps } from "./BaseEntityDialog";

type Props<T extends yup.AnyObject> = Omit<
  BaseEntityDialogProps<T>,
  "submitUrl" | "submitMethod" | "action" | "fields"
> & { deleteUrl: BaseEntityDialogProps<T>["submitUrl"] };

export const DeleteEntityDialog = <T extends yup.AnyObject>({
  entityName,
  initialValues: defaultValues,
  onSuccess,
  deleteUrl,
  open,
  title,
  description,
  onOpenChange,
}: Props<T>) => {
  return (
    <BaseEntityDialog
      action="Delete"
      submitMethod={"DELETE"}
      submitUrl={deleteUrl}
      entityName={entityName}
      initialValues={defaultValues}
      onSuccess={onSuccess}
      open={open}
      onOpenChange={onOpenChange}
      title={title}
      description={description}
      submitText={"Delete"}
      submitColor={MaterialColor.ERROR}
    />
  );
};
