import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { generateId, GenerateIdPrefix } from "../../helpers/generateId";
import { RootState } from "../RootState";

export type Player = { id: string } & PlayerPayload;
export type PlayerPayload = {
  email: string;
  firstName: string;
  lastName?: string;
  playerName?: string;
  otherEmails: string[];
};

const playersAdapter = createEntityAdapter<Player>({
  selectId: (player) => player.id,
  sortComparer: (a, b) =>
    (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName),
});

const playersSlice = createSlice({
  name: "players",
  initialState: playersAdapter.getInitialState(),
  reducers: {
    playerCreated(state, action: PayloadAction<PlayerPayload>) {
      const player = {
        ...action.payload,
        id: generateId(GenerateIdPrefix.Player),
      };
      playersAdapter.addOne(state, player);
    },
    playerCreatedMany(state, action: PayloadAction<PlayerPayload[]>) {
      const players = action.payload.map((item) => ({
        ...item,
        id: generateId(GenerateIdPrefix.Player),
      }));
      playersAdapter.addMany(state, players);
    },
    playerUpdated: playersAdapter.updateOne,
    playerRemoved: playersAdapter.removeOne,
    playerSetAll: playersAdapter.setAll,
  },
});

export const playersReducer = playersSlice.reducer;
export const playersActions = playersSlice.actions;

export const playersSelectors = playersAdapter.getSelectors<RootState>(
  (state) => state.players
);

export type PlayersState = ReturnType<typeof playersAdapter.getInitialState>;
