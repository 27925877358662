import {
  HourglassEmpty,
  Check,
  FavoriteBorder,
  ReceiptOutlined,
  ReplyOutlined,
  Clear,
} from "@mui/icons-material";
import {
  Chip,
  Button,
  Typography,
  Table,
  TableRow,
  TableCell,
  Badge,
  Box,
} from "@mui/material";
import { orange, blue, green, red, grey, purple } from "@mui/material/colors";
import {
  ApplicationWith,
  DepositStatus,
  FeedbackDepositAction,
  MeetupModel,
} from "@pastukh-dm/wboard-games";
import { useMemo } from "react";
import { ChipWithMenu } from "src/components/ChipWithMenu";
import { CopyToClipboard } from "src/components/CopyToClipboard";
import { ListTable, ListTableColumns } from "src/components/ListTable";
import { TotalDeposits } from "src/components/TotalDeposits";
import { useFetch } from "src/hooks/useFetch";
import { useRequest } from "src/hooks/useRequest";
import { FeedbackChip } from "../components/FeedbackChip";
import { DepositStatusChip } from "../components/DepositStatusChip";

type Props = { meetup: MeetupModel };

export const MeetupDepositsTab = ({ meetup }: Props) => {
  const { data: applications, refetch } = useFetch<
    ApplicationWith<"user" | "deposit" | "feedback">[]
  >({
    url: `/meetups/${meetup.id}/applications`,
  });

  const { request, isLoading } = useRequest();

  const columns = useMemo<
    ListTableColumns<ApplicationWith<"user" | "deposit" | "feedback">>
  >(() => {
    return [
      // {
      //   label: "Deposit ID",
      //   render: (item) =>
      //     item.deposit ? (
      //       <CopyToClipboard
      //         content={item.deposit?.id}
      //         maxLength={8}
      //         truncatePosition="middle"
      //       />
      //     ) : (
      //       "–"
      //     ),
      // },
      {
        label: "User",
        render: (item) => (
          <>
            <b>{item.user.name} </b>({item.user.email})
          </>
        ),
        width: 300,
      },
      // {
      //   label: "Deposit Amount",
      //   render: (item) => <>{item.deposit ? item.deposit.amount : "–"}</>,
      // },
      {
        label: "Application Status",
        render: (item) => <>{item.status}</>,
        width: 200,
      },
      {
        label: "Rating",
        render: (item) => <>{item.feedback?.rating}</>,
        width: 100,
      },
      {
        label: "Feedback",
        render: (item) => (
          <Box sx={{ borderLeft: `3px solid ${grey[200]}`, pl: 1 }}>
            <i>{item.feedback?.comments}</i>
          </Box>
        ),
        width: 500,
      },
      {
        label: "Method",
        render: (item) => <>{item.transferMethod}</>,
        width: 100,
      },
      {
        label: "Deposit Feedback",
        render: (item) => (
          <>{item.feedback ? <FeedbackChip feedback={item.feedback} /> : "–"}</>
        ),
        width: 100,
      },
      {
        label: "Deposit Status",
        render: (item) => <DepositStatusChip item={item} onChange={refetch} />,
      },
    ];
  }, []);

  const { pending, received, donated, returned, other, not_received } = useMemo(
    () =>
      (applications || [])
        .sort((a, b) =>
          !!a.user.name && !!b.user.name && a.user.name > b.user.name ? 1 : -1
        )
        // .sort((a, b) => (dayjs(a.updatedAt).isBefore(b.updatedAt) ? 1 : -1))
        .reduce(
          (res, item) => {
            if (item.deposit?.status === DepositStatus.PENDING) {
              res.pending.push(item);
            } else if (item.deposit?.status === DepositStatus.RECEIVED) {
              res.received.push(item);
            } else if (item.deposit?.status === DepositStatus.NOT_RECEIVED) {
              res.not_received.push(item);
            } else if (item.deposit?.status === DepositStatus.RETURNED) {
              res.returned.push(item);
            } else if (item.deposit?.status === DepositStatus.DONATED) {
              res.donated.push(item);
            } else {
              res.other.push(item);
            }
            return res;
          },
          {
            pending: [] as ApplicationWith<"user" | "deposit" | "feedback">[],
            received: [] as ApplicationWith<"user" | "deposit" | "feedback">[],
            not_received: [] as ApplicationWith<
              "user" | "deposit" | "feedback"
            >[],
            donated: [] as ApplicationWith<"user" | "deposit" | "feedback">[],
            returned: [] as ApplicationWith<"user" | "deposit" | "feedback">[],
            other: [] as ApplicationWith<"user" | "deposit" | "feedback">[],
          }
        ),
    [applications]
  );

  return (
    <>
      {applications && (
        <>
          {pending.length > 0 && (
            <Box sx={{ mb: 6 }}>
              <Typography variant="h5" mb={2}>
                <Badge badgeContent={pending.length} sx={{ pr: "13px" }}>
                  Pending
                </Badge>
              </Typography>
              <Box sx={{ height: "auto", width: "100%" }}>
                <ListTable data={pending} columns={columns} />
              </Box>
            </Box>
          )}
          {received.length > 0 && (
            <Box sx={{ mb: 6 }}>
              <Typography variant="h5" mb={2}>
                <Badge badgeContent={received.length} sx={{ pr: "13px" }}>
                  Received
                </Badge>
              </Typography>
              <Box sx={{ height: "auto", width: "100%" }}>
                <ListTable data={received} columns={columns} />
              </Box>
            </Box>
          )}
          {not_received.length > 0 && (
            <Box sx={{ mb: 6 }}>
              <Typography variant="h5" mb={2}>
                <Badge badgeContent={not_received.length} sx={{ pr: "13px" }}>
                  Not Received
                </Badge>
              </Typography>
              <Box sx={{ height: "auto", width: "100%" }}>
                <ListTable data={not_received} columns={columns} />
              </Box>
            </Box>
          )}
          {returned.length > 0 && (
            <Box sx={{ mb: 6 }}>
              <Typography variant="h5" mb={2}>
                <Badge badgeContent={returned.length} sx={{ pr: "13px" }}>
                  Returned
                </Badge>
              </Typography>
              <Box sx={{ height: "auto", width: "100%" }}>
                <ListTable data={returned} columns={columns} />
              </Box>
            </Box>
          )}
          {donated.length > 0 && (
            <Box sx={{ mb: 6 }}>
              <Typography variant="h5" mb={2}>
                <Badge badgeContent={donated.length} sx={{ pr: "13px" }}>
                  Donated
                </Badge>
              </Typography>
              <Box sx={{ height: "auto", width: "100%" }}>
                <ListTable data={donated} columns={columns} />
              </Box>
            </Box>
          )}
          {other.length > 0 && (
            <Box sx={{ mb: 6 }}>
              <Typography variant="h5" mb={2}>
                <Badge badgeContent={other.length} sx={{ pr: "13px" }}>
                  Other
                </Badge>
              </Typography>
              <Box sx={{ height: "auto", width: "100%" }}>
                <ListTable data={other} columns={columns} />
              </Box>
            </Box>
          )}
          <TotalDeposits applications={applications} />
        </>
      )}
    </>
  );
};

export const depositStatusRecord: Record<
  DepositStatus,
  { icon: JSX.Element; color: string; bgColor: string }
> = {
  [DepositStatus.REQUESTED]: {
    icon: <HourglassEmpty />,
    color: orange[900],
    bgColor: orange[100],
  },
  [DepositStatus.PENDING]: {
    icon: <ReceiptOutlined />,
    color: blue[900],
    bgColor: blue[100],
  },
  [DepositStatus.RECEIVED]: {
    icon: <Check />,
    color: green[900],
    bgColor: green[100],
  },
  [DepositStatus.DONATED]: {
    icon: <FavoriteBorder />,
    color: red[900],
    bgColor: red[100],
  },
  [DepositStatus.RETURNED]: {
    icon: <ReplyOutlined />,
    color: grey[900],
    bgColor: grey[100],
  },
  [DepositStatus.NOT_RECEIVED]: {
    icon: <Clear />,
    color: purple[900],
    bgColor: purple[100],
  },
  [DepositStatus.NOT_NEEDED]: {
    icon: <Clear />,
    color: purple[800],
    bgColor: purple[200],
  },
};
export const depositActionRecord: Record<
  FeedbackDepositAction,
  { icon: JSX.Element; color: string; bgColor: string }
> = {
  [FeedbackDepositAction.DONATE]: {
    icon: <FavoriteBorder />,
    color: red[900],
    bgColor: red[100],
  },
  [FeedbackDepositAction.RETURN]: {
    icon: <ReplyOutlined />,
    color: grey[900],
    bgColor: grey[100],
  },
};
