import { Button, ButtonProps } from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { AppDialog } from "./AppDialog";

type Props = PropsWithChildren<
  ButtonProps & {
    text?: string;
    icon?: React.ReactNode;
    dialogTitle: string;
    onSubmit: () => Promise<boolean>;
    onClose?: () => void;
    isLoading?: boolean;
  }
>;

export const ButtonDialog = ({
  text,
  icon,
  dialogTitle,
  onSubmit,
  onClose,
  children,
  isLoading,
  // open,
  ...buttonProps
}: Props) => {
  const [currentOpen, setCurrentOpen] = useState(false);

  // useEffect(() => {
  //   setCurrentOpen(open);
  // }, [open]);

  function handleButtonClick() {
    setCurrentOpen(true);
  }

  function handleClose() {
    onClose?.();
    setCurrentOpen(false);
  }
  async function handleSubmit() {
    const close = await onSubmit();
    if (close === true) {
      setCurrentOpen(false);
    }
  }

  return (
    <>
      <Button {...buttonProps} onClick={handleButtonClick}>
        {icon} {text}
      </Button>
      <AppDialog
        // {...dialog}
        title={dialogTitle}
        open={currentOpen}
        onClose={handleClose}
        onSubmit={handleSubmit}
        content={children}
        closeButton={{ props: { disabled: isLoading } }}
        submitButton={{ props: { disabled: isLoading } }}
      />
    </>
  );
};
