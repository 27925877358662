import { Box, Checkbox } from "@mui/material";
import {
  ApplicationModel,
  GamesOnMeetupsWith,
  GameVoteExperience,
  GameVoteInterest,
  GameVoteModel,
  MeetupModel,
} from "@pastukh-dm/wboard-games";
import { uniqBy } from "lodash";
import { ListTable } from "src/components/ListTable";
import { useFetch } from "src/hooks/useFetch";
import { useRequest } from "src/hooks/useRequest";

type Props = { meetup: MeetupModel };

export const MeetupPlanCooldownTab = ({ meetup }: Props) => {
  const { data: meetupGames } = useFetch<GamesOnMeetupsWith<"game">[]>({
    url: `/meetups/${meetup.id}/games`,
  });
  const { data: applications, refetch: refetchApplications } = useFetch<
    ApplicationModel[]
  >({
    url: `/meetups/${meetup.id}/applications`,
  });
  const { data: gameVotes, refetch: refetchVotes } = useFetch<GameVoteModel[]>({
    url: `/meetups/${meetup.id}/votes`,
  });
  const assignedApplications = applications?.filter((a) => !!a.assignedGameId);

  const refetch = () => {
    refetchVotes();
    refetchApplications();
  };
  const { request } = useRequest();

  const cooldownGames = (meetupGames || [])
    .filter((g) => !g.isMainGame)
    .sort((a, b) => (a.game!.weight! > b.game!.weight! ? -1 : 1));

  return (
    <>
      {cooldownGames && (
        <>
          <ListTable
            columns={[
              {
                label: "Brought",
                render: (item) => (
                  <>
                    <Checkbox
                      defaultChecked={item.isBrought}
                      onChange={async (e, checked) => {
                        await request(
                          { isBrought: checked },
                          {
                            method: "patch",
                            url: `/meetups/${meetup.id}/games/${item.gameId}`,
                          }
                        );
                        refetch();
                      }}
                    />
                  </>
                ),
              },
              { label: "GeekID", render: (item) => item.game.geekId },
              { label: "Name", render: (item) => item.game.name },
              {
                label: "Weight",
                render: (item) => item.game.weight?.toFixed(2),
              },
              {
                label: "Players count",
                render: (item) => {
                  const count = uniqBy(
                    assignedApplications?.filter(
                      (a) => a.assignedGameId === item.gameId
                    ),
                    (a) => `${a.id}${a.assignedGameId}`
                  ).length;
                  const max = item.game.maxPlayers;
                  return (
                    <Box
                      sx={{
                        color:
                          count === max
                            ? "primary.main"
                            : count > max
                            ? "error.main"
                            : undefined,
                        fontWeight:
                          count === max || count > max ? 800 : undefined,
                      }}
                    >
                      {count}/{max}
                    </Box>
                  );
                },
              },
              {
                label: "Know",
                render: (item) =>
                  gameVotes?.filter(
                    (v) =>
                      v.gameId === item.gameId &&
                      v.experience === GameVoteExperience.YES
                  ).length,
              },
              {
                label: "Love",
                render: (item) =>
                  gameVotes?.filter(
                    (v) =>
                      v.gameId === item.gameId &&
                      v.interest === GameVoteInterest.LOVE
                  ).length,
              },
              {
                label: "Like",
                render: (item) =>
                  gameVotes?.filter(
                    (v) =>
                      v.gameId === item.gameId &&
                      v.interest === GameVoteInterest.LIKE
                  ).length,
              },
              {
                label: "Dislike",
                render: (item) =>
                  gameVotes?.filter(
                    (v) =>
                      v.gameId === item.gameId &&
                      v.interest === GameVoteInterest.DISLIKE
                  ).length,
              },
            ]}
            data={cooldownGames}
          />
        </>
      )}
    </>
  );
};
