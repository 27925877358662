import { Alert, Button, IconButton, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { ReactNode, useMemo, useState } from "react";
import { AnyObjectSchema } from "yup";
import { useNotification } from "../hooks/useNotification";
import { AppBaseDialog } from "./dialogs/AppBaseDialog";
import { DeprecatedEntityForm } from "./EntityTable/DeprecatedEntityForm";

type Props = {
  url: string;
  method?: "post" | "delete";
  entityName?: string;
  items: EntityItem[];
  onSuccess?: () => void;
  icon: ReactNode;
  validationSchema?: AnyObjectSchema | any;
  actionName: string;
  defaultValues?: (e: any[]) => any;
  skipDialog?: boolean;
};

export type EntityItem<
  T = {
    [key: string]: any;
  }
> = {
  id: string;
} & T;

export const ListTableMultipleAction = ({
  url,
  entityName,
  actionName,
  items,
  onSuccess,
  icon,
  method,
  validationSchema,
  defaultValues,
  skipDialog,
}: Props) => {
  const [open, setOpen] = useState(false);

  function handleButtonClick() {
    if (skipDialog) {
      onSubmit();
    } else {
      setOpen(true);
    }
  }

  const { showNotification } = useNotification();
  const [error, setError] = useState<string | false>(false);
  const onSubmit = async (data?: any) => {
    try {
      setError(false);
      const res = await axios<{ message: string }>({
        url,
        method: method || "post",
        data: { ids: items.map((item) => item.id), payload: data },
      });
      showNotification({
        severity: "success",
        message: res.data.message,
      });
      onSuccess?.();
      setOpen(false);
    } catch (e: any) {
      const message =
        e?.response?.data?.error || e?.response?.data?.message || "Error";
      setError(message);
      showNotification({
        severity: "error",
        message,
      });
    }
  };

  // const shouldHide = useMemo(() => {
  //   if (hide) {
  //     return hide(item);
  //   }
  //   return false;
  // }, [hide, item]);
  // const shouldShow = useMemo(() => {
  //   if (show) {
  //     return show(item);
  //   }
  //   return false;
  // }, [show, item]);
  // const shouldDisable = useMemo(() => {
  //   if (disable) {
  //     return disable(item);
  //   }
  //   return false;
  // }, [disable, item]);

  // if ((!!show && !shouldShow) || shouldHide) {
  //   return null;
  // }

  return (
    <>
      <Tooltip title={actionName}>
        <IconButton
          onClick={handleButtonClick}
          // disabled={shouldDisable}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <AppBaseDialog
        open={open}
        title={`${actionName} ${entityName}?`}
        maxWidth="sm"
        content={
          <>
            {validationSchema ? (
              <DeprecatedEntityForm
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                defaultValues={defaultValues?.(items)}
              />
            ) : (
              <>
                {items.map((item) => (
                  <Typography mb={4}>{item?.name || item?.id}</Typography>
                ))}
                <Button variant="contained" onClick={() => onSubmit()}>
                  {actionName}
                </Button>
                {error && (
                  <Alert severity="error" sx={{ mt: 4 }}>
                    {error}
                  </Alert>
                )}
              </>
            )}
          </>
        }
      />
    </>
  );
};
