import { TextField, IconButton, Stack } from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import {
  useFieldArray,
  Control,
  FieldValues,
  Controller,
  FieldErrors,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface TextFieldArrayProps {
  control: Control<FieldValues>;
  name: string;
  errors: FieldErrors;
}

export const TextFieldArray = ({
  control,
  name,
  errors,
}: TextFieldArrayProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const fieldName = (index: number) => `${name}[${index}]`;

  const handleAppend = () => {
    append("");
  };

  const handleRemove = (indexToRemove: number) => {
    remove(indexToRemove);
  };

  return (
    <>
      {fields.map((item, index) => (
        <div key={item.id}>
          <Stack direction="row">
            <Controller
              render={({ field }) => (
                <>
                  <TextField
                    label={`${name} ${index}`}
                    variant="outlined"
                    fullWidth
                    error={!!(errors?.[name] as any)?.[index]}
                    helperText={
                      <ErrorMessage errors={errors} name={fieldName(index)} />
                    }
                    {...field}
                  />
                </>
              )}
              name={fieldName(index)}
              control={control}
            />
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleRemove(index);
              }}
            >
              <RemoveIcon />
            </IconButton>
          </Stack>
        </div>
      ))}
      <IconButton onClick={handleAppend}>
        <AddIcon />
      </IconButton>
    </>
  );
};
